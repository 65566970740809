import React, { Component, lazy } from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'

import { WithStyles } from '@mui/styles'

import { ReactComponent as protectionLogo } from '../../../assets/imgs/escape.pages/protection.svg'
import { Box, SvgIcon } from '@mui/material'

const EscapePage = lazy(() => import('./escape.component'))

type Props = WithTranslation & WithStyles<any>

/**
 * Page that renders access denied escape page.
 * @alias AccessDenied
 * @component
 * @category Components
 * @subcategory Escape Pages
 */
class AccessDeniedComponent extends Component<Props> {
    public componentDidMount() {
        const parentApp: string = `${process.env.REACT_APP_MAIN_APP || ''}`
        window.setTimeout(() => {
            if (!window?.parent || window?.parent === window) {
                window.location.replace(`${parentApp}/access_denied`)
            }
        }, 0)
    }

    /**
     * @public
     * @returns {React.ReactNode} Render the escape page.
     */
    public render() {
        const { t } = this.props

        const image = <Box title={t('ESCAPE_PAGE.ACCESS_DENIED.TITLE')}>
            <SvgIcon
                component={protectionLogo}
                viewBox="0 0 64 64"
                style={{ fontSize: 150 }}/>
        </Box>

        return <EscapePage
            image={image}
            title={t('ESCAPE_PAGE.ACCESS_DENIED.TITLE')}
            helmet={t('ESCAPE_PAGE.ACCESS_DENIED.HELMET')}
            description={t('ESCAPE_PAGE.ACCESS_DENIED.DESCRIPTION')}/>
    }
}

const AccessDenied: any = withTranslation()(AccessDeniedComponent)

export default AccessDenied
