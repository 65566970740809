import { JsonUtils } from '../../utils/json.util'

class Address {
    private _district: string | undefined
    private _city: string | undefined

    get district(): string | undefined {
        return this._district
    }

    set district(value: string | undefined) {
        this._district = value
    }

    get city(): string | undefined {
        return this._city
    }

    set city(value: string | undefined) {
        this._city = value
    }

    public fromJSON(json: any): Address {

        if (!json) {
            return this
        }

        if (typeof json === 'string') {
            if (!JsonUtils.isJsonString(json)) {
                return this
            }
            json = JSON.parse(json)
        }

        if (json.district !== undefined) {
            this.district = json.district
        }

        if (json.city !== undefined) {
            this.city = json.city
        }

        return this
    }

    public toJSON(): any {
        return {
            district: this.district,
            city: this.city
        }
    }

    public isEqual(compare: Address): boolean {
        return this.city === compare.city && this.district === compare.district
    }
}

export default Address
