import { JsonUtils } from '../../utils/json.util'
import { YesOrNo } from '../../utils/yes.or.no'

class WorkAccidentRecord {
    private _has_route_accident: YesOrNo | undefined
    private _has_occupational_illnesses: YesOrNo | undefined
    private _occupational_illnesses: string | undefined
    private _has_medical_certificates_less_than_15_days: YesOrNo | undefined
    private _medical_certificates_less_than_15_days_number: number | undefined
    private _has_medical_certificates_older_than_15_days: YesOrNo | undefined
    private _medical_certificates_older_than_15_days_number: number | undefined
    private _has_stability: YesOrNo | undefined

    get has_occupational_illnesses(): YesOrNo | undefined {
        return this._has_occupational_illnesses
    }

    set has_occupational_illnesses(value: YesOrNo | undefined) {
        this._has_occupational_illnesses = value
    }

    get occupational_illnesses(): string | undefined {
        return this._occupational_illnesses
    }

    set occupational_illnesses(value: string | undefined) {
        this._occupational_illnesses = value
    }

    get has_medical_certificates_less_than_15_days(): YesOrNo | undefined {
        return this._has_medical_certificates_less_than_15_days
    }

    set has_medical_certificates_less_than_15_days(value: YesOrNo | undefined) {
        this._has_medical_certificates_less_than_15_days = value
    }

    get medical_certificates_less_than_15_days_number(): number | undefined {
        return this._medical_certificates_less_than_15_days_number
    }

    set medical_certificates_less_than_15_days_number(value: number | undefined) {
        this._medical_certificates_less_than_15_days_number = value
    }

    get has_medical_certificates_older_than_15_days(): YesOrNo | undefined {
        return this._has_medical_certificates_older_than_15_days
    }

    set has_medical_certificates_older_than_15_days(value: YesOrNo | undefined) {
        this._has_medical_certificates_older_than_15_days = value
    }

    get medical_certificates_older_than_15_days_number(): number | undefined {
        return this._medical_certificates_older_than_15_days_number
    }

    set medical_certificates_older_than_15_days_number(value: number | undefined) {
        this._medical_certificates_older_than_15_days_number = value
    }

    get has_route_accident(): YesOrNo | undefined {
        return this._has_route_accident
    }

    set has_route_accident(value: YesOrNo | undefined) {
        this._has_route_accident = value
    }

    get has_stability(): YesOrNo | undefined {
        return this._has_stability
    }

    set has_stability(value: YesOrNo | undefined) {
        this._has_stability = value
    }

    public fromJSON(json: any): WorkAccidentRecord {

        if (!json) {
            return this
        }

        if (typeof json === 'string') {
            if (!JsonUtils.isJsonString(json)) {
                return this
            }
            json = JSON.parse(json)
        }

        if (json.has_occupational_illnesses !== undefined) {
            this.has_occupational_illnesses = YesOrNo[json.has_occupational_illnesses]
        }

        if (json.occupational_illnesses !== undefined) {
            this.occupational_illnesses = json.occupational_illnesses
        }

        if (json.has_medical_certificates_less_than_15_days !== undefined) {
            this.has_medical_certificates_less_than_15_days = YesOrNo[json.has_medical_certificates_less_than_15_days]
        }

        if (json.medical_certificates_less_than_15_days_number !== undefined) {
            this.medical_certificates_less_than_15_days_number = Number(json.medical_certificates_less_than_15_days_number)
        }

        if (json.has_medical_certificates_older_than_15_days !== undefined) {
            this.has_medical_certificates_older_than_15_days = YesOrNo[json.has_medical_certificates_older_than_15_days]
        }

        if (json.medical_certificates_older_than_15_days_number !== undefined) {
            this.medical_certificates_older_than_15_days_number = Number(json.medical_certificates_older_than_15_days_number)
        }

        if (json.has_route_accident !== undefined) {
            this.has_route_accident = YesOrNo[json.has_route_accident]
        }

        if (json.has_stability !== undefined) {
            this.has_stability = YesOrNo[json.has_stability]
        }

        return this
    }

    public toJSON(): any {
        return {
            has_occupational_illnesses: this.has_occupational_illnesses ? this.has_occupational_illnesses : undefined,
            occupational_illnesses: this.occupational_illnesses ? this.occupational_illnesses : undefined,
            has_medical_certificates_less_than_15_days: this.has_medical_certificates_less_than_15_days ?
                this.has_medical_certificates_less_than_15_days
                : undefined,
            medical_certificates_less_than_15_days_number: this.medical_certificates_less_than_15_days_number ?
                this.medical_certificates_less_than_15_days_number
                : undefined,
            has_medical_certificates_older_than_15_days: this.has_medical_certificates_older_than_15_days ?
                this.has_medical_certificates_older_than_15_days
                : undefined,
            medical_certificates_older_than_15_days_number: this.medical_certificates_older_than_15_days_number ?
                this.medical_certificates_older_than_15_days_number
                : undefined,
            has_route_accident: this.has_route_accident ? this.has_route_accident : undefined,
            has_stability: this.has_stability ? this.has_stability : undefined
        }
    }

    public isEqual(compare: WorkAccidentRecord): boolean {
        return this.has_occupational_illnesses === compare.has_occupational_illnesses &&
            this.occupational_illnesses === compare.occupational_illnesses &&
            this.has_medical_certificates_less_than_15_days === compare.has_medical_certificates_less_than_15_days &&
            this.medical_certificates_less_than_15_days_number === compare.medical_certificates_less_than_15_days_number &&
            this.has_medical_certificates_older_than_15_days === compare.has_medical_certificates_older_than_15_days &&
            this.medical_certificates_older_than_15_days_number === compare.medical_certificates_older_than_15_days_number &&
            this.has_route_accident === compare.has_route_accident &&
            this.has_stability === compare.has_stability
    }
}

export default WorkAccidentRecord
