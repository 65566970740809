export enum TransportedLoadTypes {
    // Passengers
    school_passengers = 'school_passengers',
    interurban_passenger_collective = 'interurban_passenger_collective',
    intercity_passenger_collective = 'intercity_passenger_collective',
    international_or_interstate_passenger_collective = 'international_or_interstate_passenger_collective',
    taxi = 'taxi',
    // Loads
    valuables = 'valuables',
    dangerous_products = 'dangerous_products',
    indivisible = 'indivisible',
    dry_or_bulk = 'dry_or_bulk',
    fractional = 'fractional',
    liquid = 'liquid',
    live = 'live',
    other_loads = 'other_loads',
    //
    vehicle_rental = 'vehicle_rental',
    mototaxists = 'mototaxists',
    motorfretists = 'motorfretists',
    other = 'other'
}

