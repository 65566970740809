import SociodemographicRecord from './sociodemographic.record'
import AnthropometricRecord from './anthropometric.record'
import HealthRecord from './health.record'
import { JsonUtils } from '../../utils/json.util'

class BioDemographicRecord {
    private _sociodemographic_record: SociodemographicRecord | undefined
    private _anthropometric_record: AnthropometricRecord | undefined
    private _health_record: HealthRecord | undefined

    constructor() {
        this.sociodemographic_record = new SociodemographicRecord()
        this.anthropometric_record = new AnthropometricRecord()
        this.health_record = new HealthRecord()
    }

    get sociodemographic_record(): SociodemographicRecord | undefined {
        return this._sociodemographic_record
    }

    set sociodemographic_record(value: SociodemographicRecord | undefined) {
        this._sociodemographic_record = value
    }

    get anthropometric_record(): AnthropometricRecord | undefined {
        return this._anthropometric_record
    }

    set anthropometric_record(value: AnthropometricRecord | undefined) {
        this._anthropometric_record = value
    }

    get health_record(): HealthRecord | undefined {
        return this._health_record
    }

    set health_record(value: HealthRecord | undefined) {
        this._health_record = value
    }

    public fromJSON(json: any): BioDemographicRecord {
        if (!json) {
            return this
        }

        if (typeof json === 'string') {
            if (!JsonUtils.isJsonString(json)) {
                return this
            }
            json = JSON.parse(json)
        }

        if (json.sociodemographic_record !== undefined) {
            this.sociodemographic_record = new SociodemographicRecord().fromJSON(json.sociodemographic_record)
        }

        if (json.anthropometric_record !== undefined) {
            this.anthropometric_record = new AnthropometricRecord().fromJSON(json.anthropometric_record)
        }

        if (json.health_record !== undefined) {
            this.health_record = new HealthRecord().fromJSON(json.health_record)
        }

        return this
    }

    public toJSON(): any {
        return {
            sociodemographic_record: this.sociodemographic_record?.toJSON(),
            anthropometric_record: this.anthropometric_record?.toJSON(),
            health_record: this.health_record?.toJSON()
        }
    }

    public isEqual(compare: BioDemographicRecord): boolean {
        const sociodemographic: boolean = this.sociodemographic_record && compare.sociodemographic_record ?
            this.sociodemographic_record.isEqual(compare.sociodemographic_record)
            : this.sociodemographic_record === compare.sociodemographic_record
        const anthropometric: boolean = this.anthropometric_record && compare.anthropometric_record ?
            this.anthropometric_record.isEqual(compare.anthropometric_record)
            : this.anthropometric_record === compare.anthropometric_record
        const health: boolean = this.health_record && compare.health_record ?
            this.health_record.isEqual(compare.health_record)
            : this.health_record === compare.health_record
        return sociodemographic && anthropometric && health
    }
}

export default BioDemographicRecord
