import { Entity } from '../entity/user'
import OccupationalDataRecord from './occupational.data.record'
import LaboratoryRecord from './laboratory.record'
import BioDemographicRecord from './biodemographic.record'
import BioImpedanceRecord from './bioimpedance.record'

class Questionnaire extends Entity {
    private _biodemographic_record: BioDemographicRecord | undefined
    private _occupational_record: OccupationalDataRecord | undefined
    private _laboratory_record: LaboratoryRecord | undefined
    private _bioimpedance_record: BioImpedanceRecord | undefined
    private _patient_id: string | undefined
    private _completed_at: string | undefined

    get biodemographic_record(): BioDemographicRecord | undefined {
        return this._biodemographic_record
    }

    set biodemographic_record(value: BioDemographicRecord | undefined) {
        this._biodemographic_record = value
    }

    get occupational_record(): OccupationalDataRecord | undefined {
        return this._occupational_record
    }

    set occupational_record(value: OccupationalDataRecord | undefined) {
        this._occupational_record = value
    }

    get laboratory_record(): LaboratoryRecord | undefined {
        return this._laboratory_record
    }

    set laboratory_record(value: LaboratoryRecord | undefined) {
        this._laboratory_record = value
    }

    get bioimpedance_record(): BioImpedanceRecord | undefined {
        return this._bioimpedance_record
    }

    set bioimpedance_record(value: BioImpedanceRecord | undefined) {
        this._bioimpedance_record = value
    }

    get patient_id(): string | undefined {
        return this._patient_id
    }

    set patient_id(value: string | undefined) {
        this._patient_id = value
    }

    get completed_at(): string | undefined {
        return this._completed_at
    }

    set completed_at(value: string | undefined) {
        this._completed_at = value
    }

    public fromJSON(json: any): Questionnaire {
        super.fromJSON(json)

        if (json.biodemographic_record !== undefined) {
            this.biodemographic_record = new BioDemographicRecord().fromJSON(json.biodemographic_record)
        }

        if (json.occupational_record !== undefined) {
            this.occupational_record = new OccupationalDataRecord().fromJSON(json.occupational_record)
        }

        if (json.laboratory_record !== undefined) {
            this.laboratory_record = new LaboratoryRecord().fromJSON(json.laboratory_record)
        }

        if (json.bioimpedance_record !== undefined) {
            this.bioimpedance_record = new BioImpedanceRecord().fromJSON(json.bioimpedance_record)
        }

        if (json.patient_id !== undefined) {
            this.patient_id = json.patient_id
        }

        if (json.completed_at !== undefined) {
            this.completed_at = json.completed_at
        }

        return this
    }

    public toJSON(): any {
        return {
            ...super.toJSON(),
            biodemographic_record: this.biodemographic_record?.toJSON(),
            occupational_record: this.occupational_record?.toJSON(),
            laboratory_record: this.laboratory_record?.toJSON(),
            bioimpedance_record: this.bioimpedance_record?.toJSON(),
            patient_id: this.patient_id,
            completed_at: this.completed_at
        }
    }

    public isEqual(compare: Questionnaire): boolean {
        const biodemographic_record: boolean = this.biodemographic_record && compare.biodemographic_record ?
            this.biodemographic_record.isEqual(compare.biodemographic_record)
            : this.biodemographic_record === compare.biodemographic_record
        const occupational: boolean = this.occupational_record && compare.occupational_record ?
            this.occupational_record.isEqual(compare.occupational_record)
            : this.occupational_record === compare.occupational_record
        const laboratory: boolean = this.laboratory_record && compare.laboratory_record ?
            this.laboratory_record.isEqual(compare.laboratory_record)
            : this.laboratory_record === compare.laboratory_record
        const bioimpedance: boolean = this.bioimpedance_record && compare.bioimpedance_record ?
            this.bioimpedance_record.isEqual(compare.bioimpedance_record)
            : this.bioimpedance_record === compare.bioimpedance_record
        return biodemographic_record && occupational && laboratory && bioimpedance
    }

    public isCompleted(): boolean {
        const biodemographic: boolean | undefined = this?.biodemographic_record?.isEqual(new BioDemographicRecord())

        const occupational: boolean | undefined = this?.occupational_record?.isEqual(new OccupationalDataRecord())

        const laboratory: boolean | undefined = this?.laboratory_record?.isEqual(new LaboratoryRecord())

        const bioimpedance: boolean | undefined = this?.bioimpedance_record?.isEqual(new BioImpedanceRecord())

        return Boolean(
            biodemographic === false &&
            occupational === false &&
            laboratory === false &&
            bioimpedance === false
        )
    }
}

export default Questionnaire