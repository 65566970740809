import { JsonUtils } from '../../utils/json.util'

export default class UserAddress {
    private _id: string | undefined
    // Required
    private _street: string | undefined
    private _number: number | undefined
    private _district: string | undefined
    private _city: string | undefined
    private _state: string | undefined
    private _zip_code: string | undefined
    private _complement: string | undefined

    get id(): string | undefined {
        return this._id
    }

    set id(value: string | undefined) {
        this._id = value
    }

    get street(): string | undefined {
        return this._street
    }

    set street(value: string | undefined) {
        this._street = value
    }

    get number(): number | undefined {
        return this._number
    }

    set number(value: number | undefined) {
        this._number = value
    }

    get district(): string | undefined {
        return this._district
    }

    set district(value: string | undefined) {
        this._district = value
    }

    get city(): string | undefined {
        return this._city
    }

    set city(value: string | undefined) {
        this._city = value
    }

    get state(): string | undefined {
        return this._state
    }

    set state(value: string | undefined) {
        this._state = value
    }

    get zip_code(): string | undefined {
        return this._zip_code
    }

    set zip_code(value: string | undefined) {
        this._zip_code = value
    }

    get complement(): string | undefined {
        return this._complement
    }

    set complement(value: string | undefined) {
        this._complement = value
    }

    public fromJSON(json: any): UserAddress {
        if (!json) {
            return this
        }

        if (typeof json === 'string') {
            if (!JsonUtils.isJsonString(json)) {
                return this
            }
            json = JSON.parse(json)
        }

        if (json.id !== undefined) {
            this.id = json.id
        }

        if (json.street !== undefined) {
            this.street = json.street
        }

        if (json.number !== undefined) {
            this.number = json.number
        }

        if (json.district !== undefined) {
            this.district = json.district
        }

        if (json.city !== undefined) {
            this.city = json.city
        }

        if (json.state !== undefined) {
            this.state = json.state
        }

        if (json.zip_code !== undefined) {
            this.zip_code = json.zip_code
        }

        if (json.complement !== undefined) {
            this.complement = json.complement
        }

        return this
    }

    public toJSON(): any {
        return {
            id: this.id ? this.id : undefined,
            street: this.street ? this.street : undefined,
            number: this.number ? this.number : undefined,
            district: this.district ? this.district : undefined,
            city: this.city ? this.city : undefined,
            state: this.state ? this.state : undefined,
            zip_code: this.zip_code ? this.zip_code : undefined,
            complement: this.complement ? this.complement : undefined
        }
    }

    public isEqual(address: UserAddress): boolean {
        return this.id === address.id &&
            this.street === address.street &&
            this.number === address.number &&
            this.district === address.district &&
            this.city === address.city &&
            this.state === address.state &&
            this.zip_code === address.zip_code &&
            this.complement === address.complement
    }
}
