import { AxiosError } from 'axios'
import localStorageService from './local.storage'
import authService from './auth'
import { MessageType } from '../application/domain/utils/message.type'
import { SnackBarMessageType } from '../application/domain/utils/snackbar.message.type'

export default class Interceptors {


    /**
     * Request Interceptors
     * Used to inject in the request header the access token saved in localStorage
     * @param request, Request in which it will be included in the access token
     */
    public static injectAccessToken(request: any): any {
        const { url, data } = request
        if (url?.match(/\/auth\/password$/ig) && !data.old_password) {
            return request
        }
        const token = localStorageService.getItem('access_token')
        if (token && request?.headers) {
            request.headers.Authorization = token ? `Bearer ${token}` : ''
        }
        return request
    }

    /**
     * Response Interceptors
     * Used to centralize the triggering of error messages returned by banckend services
     * @param error, Error object resulting from the request
     */
    public static handlerError(error: AxiosError): Promise<Error> {
        let title = ''
        let message = ''
        switch (error?.response?.status) {
            case 400:
                title = 'SNACKBAR.HANDLER_ERROR.TITLE.BAD_REQUEST'
                message = 'SNACKBAR.HANDLER_ERROR.MESSAGE.BAD_REQUEST'
                break
            case 401:
                title = 'SNACKBAR.HANDLER_ERROR.TITLE.BAD_REQUEST'
                message = 'SNACKBAR.HANDLER_ERROR.MESSAGE.BAD_REQUEST'
                authService.logout()
                break
            case 403:
                title = 'SNACKBAR.HANDLER_ERROR.TITLE.FORBIDDEN'
                message = 'SNACKBAR.HANDLER_ERROR.MESSAGE.FORBIDDEN'
                break
            case 404:
                title = 'SNACKBAR.HANDLER_ERROR.TITLE.NOT_FOUND'
                message = 'SNACKBAR.HANDLER_ERROR.MESSAGE.NOT_FOUND'
                break
            case 409:
                title = 'SNACKBAR.HANDLER_ERROR.TITLE.CONFLICT'
                message = 'SNACKBAR.HANDLER_ERROR.MESSAGE.CONFLICT'
                break
            case 429:
                title = 'SNACKBAR.HANDLER_ERROR.TITLE.TOO_MANY_REQUEST'
                message = 'SNACKBAR.HANDLER_ERROR.MESSAGE.TOO_MANY_REQUEST'
                break
            case 500:
                title = 'SNACKBAR.HANDLER_ERROR.TITLE.INTERNAL_SERVER_ERROR'
                message = 'SNACKBAR.HANDLER_ERROR.MESSAGE.INTERNAL_SERVER_ERROR'
                break
            case 502:
                title = 'SNACKBAR.HANDLER_ERROR.TITLE.BAD_GATEWAY'
                message = 'SNACKBAR.HANDLER_ERROR.MESSAGE.BAD_GATEWAY'
                break
            default:
                title = 'SNACKBAR.HANDLER_ERROR.TITLE.OPERATION_FAILED'
                message = 'SNACKBAR.HANDLER_ERROR.MESSAGE.OPERATION_FAILED'
                break
        }

        /* Time Out Error */
        if (error.code === 'ECONNABORTED') {
            title = 'SNACKBAR.HANDLER_ERROR.TITLE.TIME_OUT'
            message = 'SNACKBAR.HANDLER_ERROR.MESSAGE.TIME_OUT'
        }

        /* Network Error */
        if (error.message === 'Network Error') {
            title = 'SNACKBAR.HANDLER_ERROR.TITLE.NETWORK_ERROR'
            message = 'SNACKBAR.HANDLER_ERROR.MESSAGE.NETWORK_ERROR'
        }

        if (title || message) {
            const postMessage = {
                type: MessageType.QUESTIONNAIRE_CHANGE_SNACKBAR, payload: {
                    type: SnackBarMessageType.ERROR,
                    title,
                    message
                }
            }
            window.parent.postMessage(postMessage, `${process.env.REACT_APP_MAIN_APP}`)
        }

        return error?.response?.data ? Promise.reject(error.response.data) : Promise.reject(error)
    }

}
