import jwt from 'jsonwebtoken'
import localStorageService from './local.storage'
import { AccessToken } from '../application/domain/models/auth/access.token'

/**
 * Auth service.
 * @class
 * @category Services
 */
export class AuthService {

    /**
     * Checks whether the user is properly authenticated
     * @public
     * @returns {boolean}
     */
    public isAuthenticated(): boolean {
        try {
            const localToken: AccessToken | Error = this.verifyToken()
            return !!localToken
        } catch (e) {
            console.error(e)
            this.logout()
            return false
        }
    }

    /**
     * Decodes the jwt token saved in localStorage returning the payload
     *  AccessToken or Error with name JsonWebTokenError
     * @public
     * @see {@link https://www.npmjs.com/package/jsonwebtoken#errors--codes}
     * @returns {AccessToken}
     */
    public decodeToken(): AccessToken {
        const token = localStorageService.getItem('access_token')
        return this.decode(token)
    }

    /**
     * Decodes the jwt token saved in localStorage returning the payload
     *  AccessToken or Error with name JsonWebTokenError
     * @public
     * @see {@link https://www.npmjs.com/package/jsonwebtoken#errors--codes}
     * @returns {AccessToken}
     */
    public verifyToken(): AccessToken {
        const token = localStorageService.getItem('access_token')
        return this.verify(token)
    }

    /**
     * Performs the system logout by clearing the localstorage
     * @public
     * @returns {void}
     */
    public logout(): void {
        localStorageService.logout()
    }

    /**
     * Decodes jwt token returning the payload
     *  AccessToken or Error with name JsonWebTokenError
     * @public
     * @see {@link https://www.npmjs.com/package/jsonwebtoken#errors--codes}
     * @param {string} token Token jwt to be decoded
     * @returns {AccessToken}
     */
    public decode(token: string): AccessToken {
        const jsonDecoded: any = jwt.decode(token, { complete: true })
        return new AccessToken().fromJSON(jsonDecoded.payload)
    }

    /**
     * Validation of the signature and issuer of the token
     * AccessToken or Error with name JsonWebTokenError
     * @public
     * @see {@link https://www.npmjs.com/package/jsonwebtoken#errors--codes}
     * @param {string} token Token jwt to be validated
     * @returns {AccessToken}
     */
    public verify(token: string): AccessToken {
        if (!process.env.REACT_APP_JWT_PUBLIC_KEY) {
            throw new Error('REACT_APP_JWT_PUBLIC_KEY environment variable missing!')
        }
        if (!process.env.REACT_APP_ISSUER) {
            throw new Error('REACT_APP_ISSUER environment variable missing!')
        }
        const jwtPublicKey: string = `${process.env.REACT_APP_JWT_PUBLIC_KEY}`
        const issuer: string = `${process.env.REACT_APP_ISSUER}`
        const algorithms: any = ['RS256']
        const jsonDecoded = jwt.verify(token, jwtPublicKey, { algorithms, issuer })
        return new AccessToken().fromJSON(jsonDecoded)
    }
}

const authService: AuthService = new AuthService()

export default authService
