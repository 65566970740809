import { JsonUtils } from '../../utils/json.util'
import { YesOrNo } from '../../utils/yes.or.no'
import { VehicleType } from '../../utils/vehicle.type'
import { DailyTravelDistance } from '../../utils/daily.travel.distance'
import { TransportedLoadTypes } from '../../utils/transported.load.types'
import { TransportTypes } from '../../utils/transport.types'
import { WorkDayRoute } from '../../utils/work.day.route'
import { RolePerformed } from '../../utils/role.performed'

class WorkStationRecord {
    private _role_performed: RolePerformed | undefined
    private _role_performed_desc: string | undefined
    private _transport_types: TransportTypes[] | undefined
    private _transport_type_desc: string | undefined
    private _vehicle_transmission_type: VehicleType | undefined
    private _has_ergonomic_workstation: YesOrNo | undefined
    private _work_day_routes: WorkDayRoute[] | undefined
    private _daily_travel_distance_in_km: DailyTravelDistance | undefined
    private _transported_load_types: TransportedLoadTypes[] | undefined
    private _transported_load_type_desc: string | undefined

    get role_performed(): RolePerformed | undefined {
        return this._role_performed
    }

    set role_performed(value: RolePerformed | undefined) {
        this._role_performed = value
    }

    get role_performed_desc(): string | undefined {
        return this._role_performed_desc
    }

    set role_performed_desc(value: string | undefined) {
        this._role_performed_desc = value
    }

    get transport_types(): TransportTypes[] | undefined {
        return this._transport_types
    }

    set transport_types(value: TransportTypes[] | undefined) {
        this._transport_types = value
    }

    get transport_type_desc(): string | undefined {
        return this._transport_type_desc
    }

    set transport_type_desc(value: string | undefined) {
        this._transport_type_desc = value
    }

    get vehicle_transmission_type(): VehicleType | undefined {
        return this._vehicle_transmission_type
    }

    set vehicle_transmission_type(value: VehicleType | undefined) {
        this._vehicle_transmission_type = value
    }

    get has_ergonomic_workstation(): YesOrNo | undefined {
        return this._has_ergonomic_workstation
    }

    set has_ergonomic_workstation(value: YesOrNo | undefined) {
        this._has_ergonomic_workstation = value
    }

    get work_day_routes(): WorkDayRoute[] | undefined {
        return this._work_day_routes
    }

    set work_day_routes(value: WorkDayRoute[] | undefined) {
        this._work_day_routes = value
    }

    get daily_travel_distance_in_km(): DailyTravelDistance | undefined {
        return this._daily_travel_distance_in_km
    }

    set daily_travel_distance_in_km(value: DailyTravelDistance | undefined) {
        this._daily_travel_distance_in_km = value
    }

    get transported_load_types(): TransportedLoadTypes[] | undefined {
        return this._transported_load_types
    }

    set transported_load_types(value: TransportedLoadTypes[] | undefined) {
        this._transported_load_types = value
    }

    get transported_load_type_desc(): string | undefined {
        return this._transported_load_type_desc
    }

    set transported_load_type_desc(value: string | undefined) {
        this._transported_load_type_desc = value
    }

    public fromJSON(json: any): WorkStationRecord {

        if (!json) {
            return this
        }

        if (typeof json === 'string') {
            if (!JsonUtils.isJsonString(json)) {
                return this
            }
            json = JSON.parse(json)
        }

        if (json.role_performed !== undefined) {
            this.role_performed = RolePerformed[json.role_performed]
        }

        if (json.role_performed_desc !== undefined) {
            this.role_performed_desc = json.role_performed_desc
        }

        if (json.transport_types !== undefined) {
            this.transport_types = json
                ?.transport_types
                ?.map((item: string) => TransportTypes[item]) || []
        }

        if (json.transport_type_desc !== undefined) {
            this.transport_type_desc = json.transport_type_desc
        }

        if (json.vehicle_transmission_type !== undefined) {
            this.vehicle_transmission_type = VehicleType[json.vehicle_transmission_type]
        }

        if (json.has_ergonomic_workstation !== undefined) {
            this.has_ergonomic_workstation = YesOrNo[json.has_ergonomic_workstation]
        }

        if (json.work_day_routes !== undefined) {
            this.work_day_routes = json
                ?.work_day_routes
                ?.map((item: string) => WorkDayRoute[item]) || []
        }

        if (json.daily_travel_distance_in_km !== undefined) {
            this.daily_travel_distance_in_km = DailyTravelDistance[json.daily_travel_distance_in_km]
        }

        if (json.transported_load_types !== undefined) {
            this.transported_load_types = json
                ?.transported_load_types
                ?.map((item: string) => TransportedLoadTypes[item]) || []
        }

        if (json.transported_load_type_desc !== undefined) {
            this.transported_load_type_desc = json?.transported_load_type_desc
        }

        return this
    }

    public toJSON(): any {
        return {
            role_performed: this.role_performed ? this.role_performed : undefined,
            role_performed_desc: this.role_performed_desc ?
                this.role_performed_desc : undefined,
            transport_types: this.transport_types?.length ? this.transport_types : undefined,
            transport_type_desc: this.transport_type_desc ? this.transport_type_desc : undefined,
            vehicle_transmission_type: this.vehicle_transmission_type ? this.vehicle_transmission_type : undefined,
            has_ergonomic_workstation: this.has_ergonomic_workstation ? this.has_ergonomic_workstation : undefined,
            work_day_routes: this.work_day_routes?.length ? this.work_day_routes : undefined,
            daily_travel_distance_in_km: this.daily_travel_distance_in_km ? this.daily_travel_distance_in_km : undefined,
            transported_load_types: this.transported_load_types?.length ? this.transported_load_types : undefined,
            transported_load_type_desc: this.transported_load_type_desc?.length ? this.transported_load_type_desc : undefined
        }
    }

    public isEqual(compare: WorkStationRecord): boolean {
        const transportType: boolean = this.transport_types && compare.transport_types ?
            (
                this.transport_types
                    ?.every((transportTypeItem: TransportTypes) => compare.transport_types?.includes(transportTypeItem)) &&
                this?.transport_types?.length === compare?.transport_types?.length
            )
            : this.transport_types === compare.transport_types

        const workDayRoutes: boolean = this.work_day_routes && compare.work_day_routes ?
            (
                this.work_day_routes
                    ?.every((workDayRouteItem: WorkDayRoute) => compare.work_day_routes?.includes(workDayRouteItem)) &&
                this.work_day_routes?.length === compare?.work_day_routes?.length
            )
            : this.work_day_routes === compare.work_day_routes

        const transportedLoadTypes: boolean = this.transported_load_types && compare.transported_load_types ?
            (
                this.transported_load_types
                    ?.every((transportedItem: TransportedLoadTypes) =>
                        compare.transported_load_types?.includes(transportedItem)
                    ) &&
                this.transported_load_types?.length === compare.transported_load_types?.length
            )
            : this.transported_load_types === compare.transported_load_types

        return this.role_performed === compare.role_performed &&
            this.role_performed_desc === compare.role_performed_desc &&
            transportType &&
            this.transport_type_desc === compare.transport_type_desc &&
            this.vehicle_transmission_type === compare.vehicle_transmission_type &&
            this.has_ergonomic_workstation === compare.has_ergonomic_workstation &&
            workDayRoutes &&
            this.daily_travel_distance_in_km === compare.daily_travel_distance_in_km &&
            transportedLoadTypes &&
            this.transported_load_type_desc === compare.transported_load_type_desc
    }
}

export default WorkStationRecord
