import { all, apply, call, put, select, takeLatest } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'

import { QuestionnaireActions } from './index'
import { IActionCreate, IActionFind, IActionLoad, QuestionnaireTypes } from './types'
import questionnaireService from '../../services/questionnaire'
import { ApplicationState, IAxiosResponse } from '../root.types'
import Questionnaire from '../../application/domain/models/questionnaire/questionnaire'
import i18n from '../../i18n'
import { MessageType } from '../../application/domain/utils/message.type'
import { SnackBarMessageType } from '../../application/domain/utils/snackbar.message.type'

/**
 * <h5>Generating functions that make calls to the questionnaire management service.</h5>
 * @see {@link https://developer.mozilla.org/pt-BR/docs/Web/JavaScript/Reference/Statements/function*}
 * @typedef QuestionnaireSagas
 * @namespace QuestionnaireSagas
 * @category React
 * @subcategory Redux / Sagas
 */

/**
 * Generator function that calls the questionnaire service to create questionnaire.
 * @memberof QuestionnaireSagas
 * @alias QuestionnaireSagas.create
 * @function
 * @category React
 * @subcategory Redux / Sagas
 * @param {PayloadAction<IActionCreate>} action User triggered action data.
 */
export function* create(action: PayloadAction<IActionCreate>) {
    const { createSuccess, createFailure } = QuestionnaireActions
    try {
        const { data } = action.payload
        const prevData: Questionnaire = yield select((state: ApplicationState) => state.questionnaire.prevData)
        const created: Questionnaire = yield apply(
            questionnaireService,
            questionnaireService.create,
            [data]
        )
        yield put(createSuccess({ data: created }))
        const message = {
            type: MessageType.QUESTIONNAIRE_CHANGE_SNACKBAR, payload: {
                type: SnackBarMessageType.SUCCESS,
                title: '',
                message: i18n.t('QUESTIONNAIRE.CREATE.CREATED_SUCCESS')
            }
        }
        window.parent.postMessage(message, `${process.env.REACT_APP_MAIN_APP}`)
        yield call(checksCompleteness, prevData, created)
    } catch (err) {
        yield put(createFailure({ data: err }))
    }
}

/**
 * Generator function that calls the questionnaire service to find saved questionnaires.
 * @memberof QuestionnaireSagas
 * @alias QuestionnaireSagas.find
 * @function
 * @category React
 * @subcategory Redux / Sagas
 * @param {PayloadAction<IActionFind>} action User triggered action data.
 */
export function* find(action: PayloadAction<IActionFind>) {
    const { findSuccess, findFailure } = QuestionnaireActions
    try {
        const { patientId, questionnaireId } = action.payload
        const data: Questionnaire = yield apply(
            questionnaireService,
            questionnaireService.getById,
            [patientId, questionnaireId]
        )
        yield put(findSuccess({ data }))
    } catch (err) {
        yield put(findFailure())
    }
}

/**
 * Generator function that calls the questionnaire service to load saved questionnaires.
 * @memberof QuestionnaireSagas
 * @alias QuestionnaireSagas.load
 * @function
 * @category React
 * @subcategory Redux / Sagas
 * @param {PayloadAction<IActionLoad>} action User triggered action data.
 */
export function* load(action: PayloadAction<IActionLoad>) {
    const { loadSuccess, loadFailure } = QuestionnaireActions
    try {
        const { patientId, paginator } = action.payload
        const response: IAxiosResponse<Questionnaire[]> = yield apply(
            questionnaireService,
            questionnaireService.getAll,
            [patientId, paginator]
        )
        yield put(loadSuccess(response))
    } catch (err) {
        yield put(loadFailure())
    }
}

/**
 * Generator function that calls the questionnaire service to load saved questionnaires.
 * @memberof QuestionnaireSagas
 * @alias QuestionnaireSagas.load
 * @function
 * @category React
 * @subcategory Redux / Sagas
 * @param {PayloadAction<IActionLoad>} action User triggered action data.
 */
export function* loadMore(action: PayloadAction<IActionLoad>) {
    const { loadMoreSuccess, loadMoreFailure } = QuestionnaireActions
    try {
        const { patientId, paginator } = action.payload
        const response: IAxiosResponse<Questionnaire[]> = yield apply(
            questionnaireService,
            questionnaireService.getAll,
            [patientId, paginator]
        )
        yield put(loadMoreSuccess(response))
    } catch (err) {
        yield put(loadMoreFailure())
    }
}

/**
 * Generator function that calls the questionnaire service to update questionnaire.
 * @memberof QuestionnaireSagas
 * @alias QuestionnaireSagas.create
 * @function
 * @category React
 * @subcategory Redux / Sagas
 * @param {PayloadAction<IActionCreate>} action User triggered action data.
 */
export function* update(action: PayloadAction<IActionCreate>) {
    const { updateSuccess, updateFailure } = QuestionnaireActions
    try {
        const { data } = action.payload
        const prevData: Questionnaire = yield select((state: ApplicationState) => state.questionnaire.prevData)
        const updated: Questionnaire = yield apply(
            questionnaireService,
            questionnaireService.update,
            [data]
        )
        yield put(updateSuccess({ data: updated }))
        const message = {
            type: MessageType.QUESTIONNAIRE_CHANGE_SNACKBAR, payload: {
                type: SnackBarMessageType.SUCCESS,
                title: '',
                message: i18n.t('QUESTIONNAIRE.UPDATE.UPDATED_SUCCESS')
            }
        }
        window.parent.postMessage(message, `${process.env.REACT_APP_MAIN_APP}`)
        yield call(checksCompleteness, prevData, updated)
    } catch (err) {
        yield put(updateFailure())
    }
}

/**
 * Generator function that checks the completeness of a questionnaire against its previous data.
 * @memberof QuestionnaireSagas
 * @alias QuestionnaireSagas.create
 * @function
 * @category React
 * @subcategory Redux / Sagas
 * @param {Questionnaire} previousData Data from the previous questionnaire.
 * @param {Questionnaire} currentData Current questionnaire data.
 */
export function* checksCompleteness(previousData: Questionnaire, currentData: Questionnaire) {
    const { changeDialogCompleted } = QuestionnaireActions
    const prevCompleted: boolean = previousData?.isCompleted()
    const currentCompleted: boolean = currentData?.isCompleted()
    yield put(changeDialogCompleted({ completed: !prevCompleted && currentCompleted }))
}

const questionnaireSaga = function* () {
    yield all([
        takeLatest(QuestionnaireTypes.CREATE_REQUEST, create),
        takeLatest(QuestionnaireTypes.FIND_REQUEST, find),
        takeLatest(QuestionnaireTypes.LOAD_REQUEST, load),
        takeLatest(QuestionnaireTypes.LOAD_MORE_REQUEST, loadMore),
        takeLatest(QuestionnaireTypes.UPDATE_REQUEST, update)
    ])
}

export default questionnaireSaga
