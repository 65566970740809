import React, { Component, lazy } from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'

import { Box, SvgIcon, Theme, Typography } from '@mui/material'
import { createStyles, withStyles, WithStyles } from '@mui/styles'

import { ReactComponent as warningLogo } from '../../../assets/imgs/escape.pages/warning.svg'

const EscapePage = lazy(() => import('./escape.component'))

export const Style = (theme: Theme) => createStyles({
    image: {
        padding: 0,
        '& p': {
            fontSize: '40px',
            fontWeight: 'bold',
            marginTop: '-10px'
        }
    }
})

type Props = WithTranslation & WithStyles<typeof Style>

/**
 * Page that renders not found escape page.
 * @alias NotFound
 * @component
 * @category Components
 * @subcategory Escape Pages
 */
class NotFoundComponent extends Component<Props> {
    public componentDidMount() {
        const parentApp: string = `${process.env.REACT_APP_MAIN_APP || ''}`
        const productionMode: boolean = `${process.env.REACT_APP_ENV || ''}` === 'production'
        window.setTimeout(() => {
            if ((!window?.parent || window?.parent === window) && productionMode) {
                window.location.replace(`${parentApp}/not_found`)
            }
        }, 0)
    }

    /**
     * @public
     * @returns {React.ReactNode} Render the escape page.
     */
    public render() {
        const { t, classes } = this.props

        const image = <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            title={t('ESCAPE_PAGE.NOT_FOUND.TITLE')}
            className={classes.image}>
            <SvgIcon
                id="img_warning"
                component={warningLogo}
                viewBox="0 0 74 74"
                style={{ fontSize: 120 }}/>
            <Box p={1}>
                <Typography color="secondary">404</Typography>
            </Box>
        </Box>

        return <EscapePage
            image={image}
            helmet={t('ESCAPE_PAGE.NOT_FOUND.TITLE')}
            title={t('ESCAPE_PAGE.NOT_FOUND.TITLE')}
            description={t('ESCAPE_PAGE.NOT_FOUND.DESCRIPTION')}/>
    }
}

const NotFoundWithTranslation = withTranslation()(NotFoundComponent)

const NotFound: any = withStyles<any>(Style, { withTheme: true })(NotFoundWithTranslation)

export default NotFound
