import axiosInstance from './axios'
import Questionnaire from '../application/domain/models/questionnaire/questionnaire'
import { AxiosResponse } from 'axios'
import { IAxiosResponse, IPaginator } from '../store/root.types'
import UtilDatePicker from '../application/domain/utils/date'

/**
 * Questionnaire service, is class responsible for encapsulating methods for manipulating questionnaires.
 * @class
 * @category Services
 */
class QuestionnaireService {

    constructor(private apiVersion = 'v1') {
    }

    /**
     * Method for requesting the creation of a new questionnaire
     * @param {Questionnaire} questionnaire Questionnaire data to be created.
     * @return {Promise<Questionnaire>} Questionnaire data after creation.
     */
    public create(questionnaire: Questionnaire): Promise<Questionnaire> {
        const url: string = `${this.apiVersion}/patients/${questionnaire.patient_id}/questionnaires`
        return axiosInstance
            .post(url, questionnaire.toJSON())
            .then((response: AxiosResponse<any>) => new Questionnaire().fromJSON(response.data))
    }

    /**
     * Method for requesting to obtain a list of questionnaires from a patient.
     * @param patientId Selected patient identifier
     * @param {IPaginator} paginator Paginator settings.
     * @return {Promise<IAxiosResponse<Questionnaire[]>>} List of questionnaires obtained
     */
    public getAll(patientId: string, paginator?: IPaginator): Promise<IAxiosResponse<Questionnaire[]>> {
        const params: URLSearchParams = this.buildParams(paginator)
        params.append('sort', 'created_at')
        const url: string = `${this.apiVersion}/patients/${patientId}/questionnaires`
        return axiosInstance
            .get(url, { params })
            .then((response: AxiosResponse<any[]>) => {
                return {
                    data: response.data.map((item: any) => new Questionnaire().fromJSON(item)),
                    headers: response.headers
                }
            })
    }

    /**
     * Method for requesting data from a questionnaire.
     * @param patientId Selected patient identifier
     * @param questId Identifier of the selected questionnaire.
     * @return {Promise<Questionnaire>} Questionnaire data obtained.
     */
    public getById(patientId: string, questId: string): Promise<Questionnaire> {
        return axiosInstance
            .get(`${this.apiVersion}/patients/${patientId}/questionnaires/${questId}`)
            .then((response: AxiosResponse<any>) => new Questionnaire().fromJSON(response.data))
    }

    /**
     * Method for requesting update of questionnaire data.
     * @param {Questionnaire} questionnaire Questionnaire data for update
     * @return {Promise<Questionnaire>} Questionnaire data after update.
     */
    public update(questionnaire: Questionnaire): Promise<Questionnaire> {
        const url: string = `${this.apiVersion}/patients/${questionnaire.patient_id}/questionnaires/${questionnaire.id}`
        return axiosInstance
            .patch(url, questionnaire.toJSON())
            .then((response: AxiosResponse<any>) => new Questionnaire().fromJSON(response.data))
    }

    /**
     * Method to convert {@link IPaginator} object to {@link URLSearchParams}.
     * @param {IPaginator} [paginator] Paginator settings
     * @return {URLSearchParams} Resulting object.
     */
    public buildParams(paginator?: IPaginator): URLSearchParams {
        const params = new URLSearchParams()
        if (paginator) {

            if (paginator.page) {
                params.append('page', String(paginator.page + 1))
            }

            if (paginator.rows) {
                params.append('limit', String(paginator.rows))
            }

            const startDate: string = UtilDatePicker.formatDate(paginator?.filter?.startDate, 'YYYY-MM-DD') || ''
            const endDate: string = UtilDatePicker.formatDate(paginator?.filter?.endDate, 'YYYY-MM-DD') || ''

            if (startDate && !endDate) {
                params.append('created_at', `gte:${startDate}T00:00:00.000Z`)
                params.append('created_at', `lte:${startDate}T23:59:59.999Z`)
            } else if (startDate && endDate) {
                params.append('created_at', `gte:${startDate}T00:00:00.000Z`)
                params.append('created_at', `lte:${endDate}T23:59:59.999Z`)
            }
        }
        return params
    }

}

const questionnaireService: QuestionnaireService = new QuestionnaireService()

export default questionnaireService
