import React, { Component } from 'react'
import { IComponentRouter, withRouter } from '../components/with.router'
import localStorageService from '../services/local.storage'
import { parseStringToThemeMode, ThemeMode } from '../material.theme'
import { ApplicationState } from '../store/root.types'
import { connect } from 'react-redux'
import { IActionsChangeLanguage, IActionsChangeThemeMode, LayoutActions } from '../store/layout'
import { LanguageOptions, parseStringToEnumLanguage } from '../i18n'

interface IProps {
    readonly language: LanguageOptions
    readonly themeMode: ThemeMode
}

interface IDispatch {
    changeLanguage(props: IActionsChangeLanguage): void

    changeTheme(props: IActionsChangeThemeMode): void
}

type IJoinProps = IProps & IDispatch & IComponentRouter


/**
 * Input component for validating access data reported by the main application.
 * @alias EntryPoint
 * @component
 * @category Containers
 * @extends {Component}
 * @property {LanguageOptions} language Language selected.
 * @property {ThemeMode} themeMode Theme mode selected.
 * @property {LayoutActions.changeLanguage} changeLanguage Reduction function that changes the selected language.
 * @property {LayoutActions.changeTheme} changeTheme Reduction function that changes the selected theme.
 * @see {@link LayoutActions} Files containing layout actions.
 */
class EntryPointComponent extends Component<IJoinProps> {
    constructor(props: IJoinProps) {
        super(props)
        this.checkAuthenticate = this.checkAuthenticate.bind(this)
    }

    /**
     * Method belonging to the component's life cycle, triggered immediately after a component is assembled (inserted in the tree).
     * @see {@link https://pt-br.reactjs.org/docs/react-component.html#componentdidmount}
     * @return {void}
     */
    public componentDidMount() {
        const productionMode: boolean = `${process.env.REACT_APP_ENV || ''}` === 'production'
        if (productionMode && (!window?.parent || window?.parent === window)) {
            window.setTimeout(() => {
                const parentApp: string = `${process.env.REACT_APP_MAIN_APP || ''}`
                window.location.replace(`${parentApp}/access_denied`)
            }, 0)
        } else {
            this.checkAuthenticate()
        }
    }

    /**
     * Render method.
     * Triggering method to render the component.
     * @returns {React.ReactNode} Input component for validating access data reported by the main application.
     */
    public render() {
        return <React.Fragment/>
    }

    /**
     * Method used to validate access data reported by the main application.
     * @private
     * @return {void}
     */
    private checkAuthenticate(): void {
        window.setTimeout(() => {
            const {
                location: { search },
                navigate,
                changeTheme,
                changeLanguage
            } = this.props
            try {
                const query = new URLSearchParams(search)
                const accessToken: string = query.get('access_token') || ''
                const themeModeStr: any = query.get('theme_mode') || ''
                const languageStr: any = query.get('language') || ''
                const patientId: any = query.get('patientId') || ''
                const path: any = query.get('path') || ''
                if (!patientId) {
                    throw new Error('Unidentified patient!')
                }
                if (!accessToken) {
                    throw new Error('Not authorized!')
                }
                localStorageService.setItem('access_token', accessToken)
                const themeMode: ThemeMode = parseStringToThemeMode(themeModeStr)
                const language: LanguageOptions = parseStringToEnumLanguage(languageStr)
                if (themeMode) {
                    changeTheme({ themeMode })
                }
                if (language) {
                    changeLanguage({ language })
                }
                navigate(path)
            } catch (e: any) {
                const parentApp: string = `${process.env.REACT_APP_MAIN_APP || ''}`
                window.location.replace(`${parentApp}/access_denied`)
            }
        }, 0)
    }
}

const EntryPointWithRouter: any = withRouter(EntryPointComponent)

const mapStateToProps = (state: ApplicationState) => ({
    language: state.layout.language,
    themeMode: state.layout.themeMode
})

const EntryPoint: any = connect(mapStateToProps, LayoutActions)(EntryPointWithRouter)

export default EntryPoint
