import { Component } from 'react'
import authService from '../../services/auth'
import { TypeUser } from '../../application/domain/utils/type.user'
import { AccessToken } from '../../application/domain/models/auth/access.token'

interface IProps {
    readonly expected: TypeUser | TypeUser[]
}

export class VerifyUserType extends Component<IProps> {

    public static validate(expected: TypeUser | TypeUser[]): boolean {
        try {
            const accessToken: AccessToken = authService.decodeToken()
            const userType: TypeUser | undefined = TypeUser[`${accessToken?.sub_type?.toUpperCase()}`]

            if (Array.isArray(expected)) {
                return expected.some((allowedType: TypeUser) => allowedType === userType)
            }

            return userType === expected

        } catch (e) {
            return false
        }
    }

    public render() {
        const { expected, children } = this.props
        if (VerifyUserType.validate(expected)) {
            return children
        }
        return false
    }
}
