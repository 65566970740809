import { JsonUtils } from '../../utils/json.util'

class AnthropometricRecord {
    private _height_in_cm: number | undefined
    private _weight_in_kg: number | undefined
    private _waist_circumference_in_cm: number | undefined
    private _bmi_in_kg_m2: number | undefined

    get height_in_cm(): number | undefined {
        return this._height_in_cm
    }

    set height_in_cm(value: number | undefined) {
        this._height_in_cm = value
    }

    get weight_in_kg(): number | undefined {
        return this._weight_in_kg
    }

    set weight_in_kg(value: number | undefined) {
        this._weight_in_kg = value
    }

    get waist_circumference_in_cm(): number | undefined {
        return this._waist_circumference_in_cm
    }

    set waist_circumference_in_cm(value: number | undefined) {
        this._waist_circumference_in_cm = value
    }

    get bmi_in_kg_m2(): number | undefined {
        return this._bmi_in_kg_m2
    }

    set bmi_in_kg_m2(value: number | undefined) {
        this._bmi_in_kg_m2 = value
    }

    public fromJSON(json: any): AnthropometricRecord {

        if (!json) {
            return this
        }

        if (typeof json === 'string') {
            if (!JsonUtils.isJsonString(json)) {
                return this
            }
            json = JSON.parse(json)
        }

        if (json.height_in_cm !== undefined) {
            this.height_in_cm = Number(json.height_in_cm)
        }

        if (json.weight_in_kg !== undefined) {
            this.weight_in_kg = Number(json.weight_in_kg)
        }

        if (json.waist_circumference_in_cm !== undefined) {
            this.waist_circumference_in_cm = Number(json.waist_circumference_in_cm)
        }

        if (json.bmi_in_kg_m2 !== undefined) {
            this.bmi_in_kg_m2 = Number(json.bmi_in_kg_m2)
        }

        return this
    }

    public toJSON(): any {
        return {
            height_in_cm: this.height_in_cm ? this.height_in_cm : undefined,
            weight_in_kg: this.weight_in_kg ? this.weight_in_kg : undefined,
            waist_circumference_in_cm: this.waist_circumference_in_cm ? this.waist_circumference_in_cm : undefined,
            bmi_in_kg_m2: this.bmi_in_kg_m2 ? this.bmi_in_kg_m2 : undefined
        }
    }

    public isEqual(compare: AnthropometricRecord): boolean {
        return this.weight_in_kg === compare.weight_in_kg &&
            this.height_in_cm === compare.height_in_cm &&
            this.waist_circumference_in_cm === compare.waist_circumference_in_cm &&
            this.bmi_in_kg_m2 === compare.bmi_in_kg_m2
    }
}

export default AnthropometricRecord
