import { JsonUtils } from '../../utils/json.util'
import { YesOrNo } from '../../utils/yes.or.no'

class LaboratoryRecord {
    private _rbc_is_within_reference: YesOrNo | undefined
    private _rbc_value: string | undefined
    private _leukocytes_is_within_reference: YesOrNo | undefined
    private _leukocytes_value: string | undefined
    private _platelets_is_within_reference: YesOrNo | undefined
    private _platelets_value: string | undefined
    private _total_cholesterol_is_within_reference: YesOrNo | undefined
    private _total_cholesterol_value: string | undefined
    private _glucose_is_within_reference: YesOrNo | undefined
    private _glucose_value: string | undefined
    private _calcium_is_within_reference: YesOrNo | undefined
    private _calcium_value: string | undefined
    private _free_psa_is_within_reference: YesOrNo | undefined
    private _free_psa_value: string | undefined
    private _triglycerides_is_within_reference: YesOrNo | undefined
    private _triglycerides_value: string | undefined
    private _urea_is_within_reference: YesOrNo | undefined
    private _urea_value: string | undefined
    private _creatinine_is_within_reference: YesOrNo | undefined
    private _creatinine_value: string | undefined
    private _tgo_is_within_reference: YesOrNo | undefined
    private _tgo_value: string | undefined
    private _tgp_is_within_reference: YesOrNo | undefined
    private _tgp_value: string | undefined
    private _potassium_is_within_reference: YesOrNo | undefined
    private _potassium_value: string | undefined
    private _sodium_is_within_reference: YesOrNo | undefined
    private _sodium_value: string | undefined

    get rbc_is_within_reference(): YesOrNo | undefined {
        return this._rbc_is_within_reference
    }

    set rbc_is_within_reference(value: YesOrNo | undefined) {
        this._rbc_is_within_reference = value
    }

    get rbc_value(): string | undefined {
        return this._rbc_value
    }

    set rbc_value(value: string | undefined) {
        this._rbc_value = value
    }

    get leukocytes_is_within_reference(): YesOrNo | undefined {
        return this._leukocytes_is_within_reference
    }

    set leukocytes_is_within_reference(value: YesOrNo | undefined) {
        this._leukocytes_is_within_reference = value
    }

    get leukocytes_value(): string | undefined {
        return this._leukocytes_value
    }

    set leukocytes_value(value: string | undefined) {
        this._leukocytes_value = value
    }

    get platelets_is_within_reference(): YesOrNo | undefined {
        return this._platelets_is_within_reference
    }

    set platelets_is_within_reference(value: YesOrNo | undefined) {
        this._platelets_is_within_reference = value
    }

    get platelets_value(): string | undefined {
        return this._platelets_value
    }

    set platelets_value(value: string | undefined) {
        this._platelets_value = value
    }

    get total_cholesterol_is_within_reference(): YesOrNo | undefined {
        return this._total_cholesterol_is_within_reference
    }

    set total_cholesterol_is_within_reference(value: YesOrNo | undefined) {
        this._total_cholesterol_is_within_reference = value
    }

    get total_cholesterol_value(): string | undefined {
        return this._total_cholesterol_value
    }

    set total_cholesterol_value(value: string | undefined) {
        this._total_cholesterol_value = value
    }

    get glucose_is_within_reference(): YesOrNo | undefined {
        return this._glucose_is_within_reference
    }

    set glucose_is_within_reference(value: YesOrNo | undefined) {
        this._glucose_is_within_reference = value
    }

    get glucose_value(): string | undefined {
        return this._glucose_value
    }

    set glucose_value(value: string | undefined) {
        this._glucose_value = value
    }

    get calcium_is_within_reference(): YesOrNo | undefined {
        return this._calcium_is_within_reference
    }

    set calcium_is_within_reference(value: YesOrNo | undefined) {
        this._calcium_is_within_reference = value
    }

    get calcium_value(): string | undefined {
        return this._calcium_value
    }

    set calcium_value(value: string | undefined) {
        this._calcium_value = value
    }

    get free_psa_is_within_reference(): YesOrNo | undefined {
        return this._free_psa_is_within_reference
    }

    set free_psa_is_within_reference(value: YesOrNo | undefined) {
        this._free_psa_is_within_reference = value
    }

    get free_psa_value(): string | undefined {
        return this._free_psa_value
    }

    set free_psa_value(value: string | undefined) {
        this._free_psa_value = value
    }

    get triglycerides_is_within_reference(): YesOrNo | undefined {
        return this._triglycerides_is_within_reference
    }

    set triglycerides_is_within_reference(value: YesOrNo | undefined) {
        this._triglycerides_is_within_reference = value
    }

    get triglycerides_value(): string | undefined {
        return this._triglycerides_value
    }

    set triglycerides_value(value: string | undefined) {
        this._triglycerides_value = value
    }

    get urea_is_within_reference(): YesOrNo | undefined {
        return this._urea_is_within_reference
    }

    set urea_is_within_reference(value: YesOrNo | undefined) {
        this._urea_is_within_reference = value
    }

    get urea_value(): string | undefined {
        return this._urea_value
    }

    set urea_value(value: string | undefined) {
        this._urea_value = value
    }

    get creatinine_is_within_reference(): YesOrNo | undefined {
        return this._creatinine_is_within_reference
    }

    set creatinine_is_within_reference(value: YesOrNo | undefined) {
        this._creatinine_is_within_reference = value
    }

    get creatinine_value(): string | undefined {
        return this._creatinine_value
    }

    set creatinine_value(value: string | undefined) {
        this._creatinine_value = value
    }

    get tgo_is_within_reference(): YesOrNo | undefined {
        return this._tgo_is_within_reference
    }

    set tgo_is_within_reference(value: YesOrNo | undefined) {
        this._tgo_is_within_reference = value
    }

    get tgo_value(): string | undefined {
        return this._tgo_value
    }

    set tgo_value(value: string | undefined) {
        this._tgo_value = value
    }

    get tgp_is_within_reference(): YesOrNo | undefined {
        return this._tgp_is_within_reference
    }

    set tgp_is_within_reference(value: YesOrNo | undefined) {
        this._tgp_is_within_reference = value
    }

    get tgp_value(): string | undefined {
        return this._tgp_value
    }

    set tgp_value(value: string | undefined) {
        this._tgp_value = value
    }

    get potassium_is_within_reference(): YesOrNo | undefined {
        return this._potassium_is_within_reference
    }

    set potassium_is_within_reference(value: YesOrNo | undefined) {
        this._potassium_is_within_reference = value
    }

    get potassium_value(): string | undefined {
        return this._potassium_value
    }

    set potassium_value(value: string | undefined) {
        this._potassium_value = value
    }

    get sodium_is_within_reference(): YesOrNo | undefined {
        return this._sodium_is_within_reference
    }

    set sodium_is_within_reference(value: YesOrNo | undefined) {
        this._sodium_is_within_reference = value
    }

    get sodium_value(): string | undefined {
        return this._sodium_value
    }

    set sodium_value(value: string | undefined) {
        this._sodium_value = value
    }

    public fromJSON(json: any): LaboratoryRecord {

        if (!json) {
            return this
        }

        if (typeof json === 'string') {
            if (!JsonUtils.isJsonString(json)) {
                return this
            }
            json = JSON.parse(json)
        }

        if (json.rbc_is_within_reference !== undefined) {
            this.rbc_is_within_reference = YesOrNo[json.rbc_is_within_reference]
        }

        if (json.rbc_value !== undefined) {
            this.rbc_value = json.rbc_value
        }

        if (json.leukocytes_is_within_reference !== undefined) {
            this.leukocytes_is_within_reference = YesOrNo[json.leukocytes_is_within_reference]
        }

        if (json.leukocytes_value !== undefined) {
            this.leukocytes_value = json.leukocytes_value
        }

        if (json.platelets_is_within_reference !== undefined) {
            this.platelets_is_within_reference = YesOrNo[json.platelets_is_within_reference]
        }

        if (json.platelets_value !== undefined) {
            this.platelets_value = json.platelets_value
        }

        if (json.total_cholesterol_is_within_reference !== undefined) {
            this.total_cholesterol_is_within_reference = YesOrNo[json.total_cholesterol_is_within_reference]
        }

        if (json.total_cholesterol_value !== undefined) {
            this.total_cholesterol_value = json.total_cholesterol_value
        }

        if (json.glucose_is_within_reference !== undefined) {
            this.glucose_is_within_reference = YesOrNo[json.glucose_is_within_reference]
        }

        if (json.glucose_value !== undefined) {
            this.glucose_value = json.glucose_value
        }

        if (json.calcium_is_within_reference !== undefined) {
            this.calcium_is_within_reference = YesOrNo[json.calcium_is_within_reference]
        }

        if (json.calcium_value !== undefined) {
            this.calcium_value = json.calcium_value
        }

        if (json.free_psa_is_within_reference !== undefined) {
            this.free_psa_is_within_reference = YesOrNo[json.free_psa_is_within_reference]
        }

        if (json.free_psa_value !== undefined) {
            this.free_psa_value = json.free_psa_value
        }

        if (json.triglycerides_is_within_reference !== undefined) {
            this.triglycerides_is_within_reference = YesOrNo[json.triglycerides_is_within_reference]
        }

        if (json.triglycerides_value !== undefined) {
            this.triglycerides_value = json.triglycerides_value
        }

        if (json.urea_is_within_reference !== undefined) {
            this.urea_is_within_reference = YesOrNo[json.urea_is_within_reference]
        }

        if (json.urea_value !== undefined) {
            this.urea_value = json.urea_value
        }

        if (json.creatinine_is_within_reference !== undefined) {
            this.creatinine_is_within_reference = YesOrNo[json.creatinine_is_within_reference]
        }

        if (json.creatinine_value !== undefined) {
            this.creatinine_value = json.creatinine_value
        }

        if (json.tgo_is_within_reference !== undefined) {
            this.tgo_is_within_reference = YesOrNo[json.tgo_is_within_reference]
        }

        if (json.tgo_value !== undefined) {
            this.tgo_value = json.tgo_value
        }

        if (json.tgp_is_within_reference !== undefined) {
            this.tgp_is_within_reference = YesOrNo[json.tgp_is_within_reference]
        }

        if (json.tgp_value !== undefined) {
            this.tgp_value = json.tgp_value
        }

        if (json.potassium_is_within_reference !== undefined) {
            this.potassium_is_within_reference = YesOrNo[json.potassium_is_within_reference]
        }

        if (json.potassium_value !== undefined) {
            this.potassium_value = json.potassium_value
        }

        if (json.sodium_is_within_reference !== undefined) {
            this.sodium_is_within_reference = YesOrNo[json.sodium_is_within_reference]
        }

        if (json.sodium_value !== undefined) {
            this.sodium_value = json.sodium_value
        }

        return this
    }

    public toJSON(): any {
        return {
            rbc_is_within_reference: this.rbc_is_within_reference ? this.rbc_is_within_reference : undefined,
            rbc_value: this.rbc_value ? this.rbc_value : undefined,
            leukocytes_is_within_reference: this.leukocytes_is_within_reference ? this.leukocytes_is_within_reference : undefined,
            leukocytes_value: this.leukocytes_value ? this.leukocytes_value : undefined,
            platelets_is_within_reference: this.platelets_is_within_reference ?
                this.platelets_is_within_reference
                : undefined,
            platelets_value: this.platelets_value ? this.platelets_value : undefined,
            total_cholesterol_is_within_reference: this.total_cholesterol_is_within_reference ?
                this.total_cholesterol_is_within_reference : undefined,
            total_cholesterol_value: this.total_cholesterol_value ? this.total_cholesterol_value : undefined,
            glucose_is_within_reference: this.glucose_is_within_reference ? this.glucose_is_within_reference : undefined,
            glucose_value: this.glucose_value ? this.glucose_value : undefined,
            calcium_is_within_reference: this.calcium_is_within_reference ? this.calcium_is_within_reference : undefined,
            calcium_value: this.calcium_value ? this.calcium_value : undefined,
            free_psa_is_within_reference: this.free_psa_is_within_reference ? this.free_psa_is_within_reference : undefined,
            free_psa_value: this.free_psa_value ? this.free_psa_value : undefined,
            triglycerides_is_within_reference: this.triglycerides_is_within_reference ?
                this.triglycerides_is_within_reference : undefined,
            triglycerides_value: this.triglycerides_value ? this.triglycerides_value : undefined,
            urea_is_within_reference: this.urea_is_within_reference ? this.urea_is_within_reference : undefined,
            urea_value: this.urea_value ? this.urea_value : undefined,
            creatinine_is_within_reference: this.creatinine_is_within_reference ?
                this.creatinine_is_within_reference : undefined,
            creatinine_value: this.creatinine_value ? this.creatinine_value : undefined,
            tgo_is_within_reference: this.tgo_is_within_reference ?
                this.tgo_is_within_reference : undefined,
            tgo_value: this.tgo_value ? this.tgo_value : undefined,
            tgp_is_within_reference: this.tgp_is_within_reference ?
                this.tgp_is_within_reference : undefined,
            tgp_value: this.tgp_value ? this.tgp_value : undefined,
            potassium_is_within_reference: this.potassium_is_within_reference ?
                this.potassium_is_within_reference : undefined,
            potassium_value: this.potassium_value ? this.potassium_value : undefined,
            sodium_is_within_reference: this.sodium_is_within_reference ? this.sodium_is_within_reference : undefined,
            sodium_value: this.sodium_value ? this.sodium_value : undefined
        }
    }

    public isEqual(compare: LaboratoryRecord): boolean {
        return this.rbc_is_within_reference === compare.rbc_is_within_reference &&
            this.rbc_value === compare.rbc_value &&
            this.leukocytes_is_within_reference === compare.leukocytes_is_within_reference &&
            this.leukocytes_value === compare.leukocytes_value &&
            this.platelets_is_within_reference === compare.platelets_is_within_reference &&
            this.platelets_value === compare.platelets_value &&
            this.total_cholesterol_is_within_reference === compare.total_cholesterol_is_within_reference &&
            this.total_cholesterol_value === compare.total_cholesterol_value &&
            this.glucose_is_within_reference === compare.glucose_is_within_reference &&
            this.glucose_value === compare.glucose_value &&
            this.calcium_is_within_reference === compare.calcium_is_within_reference &&
            this.calcium_value === compare.calcium_value &&
            this.free_psa_is_within_reference === compare.free_psa_is_within_reference &&
            this.free_psa_value === compare.free_psa_value &&
            this.triglycerides_is_within_reference === compare.triglycerides_is_within_reference &&
            this.triglycerides_value === compare.triglycerides_value &&
            this.urea_is_within_reference === compare.urea_is_within_reference &&
            this.urea_value === compare.urea_value &&
            this.creatinine_is_within_reference === compare.creatinine_is_within_reference &&
            this.creatinine_value === compare.creatinine_value &&
            this.tgo_is_within_reference === compare.tgo_is_within_reference &&
            this.tgo_value === compare.tgo_value &&
            this.tgp_is_within_reference === compare.tgp_is_within_reference &&
            this.tgp_value === compare.tgp_value &&
            this.potassium_is_within_reference === compare.potassium_is_within_reference &&
            this.potassium_value === compare.potassium_value &&
            this.sodium_is_within_reference === compare.sodium_is_within_reference &&
            this.sodium_value === compare.sodium_value
    }
}

export default LaboratoryRecord
