import { Agent } from 'https'
import axios, { AxiosInstance } from 'axios'
import Interceptors from './interceptors'

/**
 * @class
 * @category Services
 */
class Axios {

    /**
     * Static method that will return the axios instance
     * @public
     * @static
     * @returns {AxiosInstance}
     */
    public static getInstance(): AxiosInstance {
        if (!this._instance) {
            this._instance = axios.create({
                baseURL: process.env.REACT_APP_API_GATEWAY,
                httpsAgent: new Agent({ rejectUnauthorized: false })
            })
            this.configRequestInterceptors()
            this.configResponseInterceptors()
        }
        return this._instance
    }

    private static _instance: AxiosInstance

    /**
     * Method for configuring the interceptors that acted on the request
     */
    private static configRequestInterceptors(): void {
        this._instance
            .interceptors
            .request
            .use(Interceptors.injectAccessToken)
    }

    /**
     * Method for configuring the interceptors that acted on the response
     */
    private static configResponseInterceptors(): void {
        this._instance
            .interceptors
            .response
            .use(
                undefined,
                Interceptors.handlerError
            )
    }

}

export default Axios.getInstance()
