import { JsonUtils } from '../../utils/json.util'
import WorkDayRecord from './work.day.record'
import WorkStationRecord from './work.stantion.record'
import WorkAccidentRecord from './work.accident.record'

class OccupationalDataRecord {
    private _work_day_record: WorkDayRecord | undefined
    private _work_station_record: WorkStationRecord | undefined
    private _work_accident_record: WorkAccidentRecord | undefined

    constructor() {
        this.work_day_record = new WorkDayRecord()
        this.work_station_record = new WorkStationRecord()
        this.work_accident_record = new WorkAccidentRecord()
    }


    get work_day_record(): WorkDayRecord | undefined {
        return this._work_day_record
    }

    set work_day_record(value: WorkDayRecord | undefined) {
        this._work_day_record = value
    }

    get work_station_record(): WorkStationRecord | undefined {
        return this._work_station_record
    }

    set work_station_record(value: WorkStationRecord | undefined) {
        this._work_station_record = value
    }

    get work_accident_record(): WorkAccidentRecord | undefined {
        return this._work_accident_record
    }

    set work_accident_record(value: WorkAccidentRecord | undefined) {
        this._work_accident_record = value
    }

    public fromJSON(json: any): OccupationalDataRecord {

        if (!json) {
            return this
        }

        if (typeof json === 'string') {
            if (!JsonUtils.isJsonString(json)) {
                return this
            }
            json = JSON.parse(json)
        }

        if (json.work_day_record !== undefined) {
            this.work_day_record = new WorkDayRecord().fromJSON(json.work_day_record)
        }

        if (json.work_station_record !== undefined) {
            this.work_station_record = new WorkStationRecord().fromJSON(json.work_station_record)
        }

        if (json.work_accident_record !== undefined) {
            this.work_accident_record = new WorkAccidentRecord().fromJSON(json.work_accident_record)
        }

        return this
    }

    public toJSON(): any {
        return {
            work_day_record: this.work_day_record?.toJSON(),
            work_station_record: this.work_station_record?.toJSON(),
            work_accident_record: this.work_accident_record?.toJSON()
        }
    }

    public isEqual(compare: OccupationalDataRecord): boolean {
        const workDay: boolean = this.work_day_record && compare.work_day_record ?
            this.work_day_record.isEqual(compare.work_day_record)
            : this.work_day_record === compare.work_day_record
        const workStation: boolean = this.work_station_record && compare.work_station_record ?
            this.work_station_record.isEqual(compare.work_station_record)
            : this.work_station_record === compare.work_station_record
        const workAccident: boolean = this.work_accident_record && compare.work_accident_record ?
            this.work_accident_record.isEqual(compare.work_accident_record)
            : this.work_accident_record === compare.work_accident_record
        return workDay && workStation && workAccident
    }
}

export default OccupationalDataRecord
