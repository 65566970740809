import { configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'

import layout from './layout'
import questionnaire from './questionnaire'

import rootSaga from './root.sagas'

const sagaMiddleware = createSagaMiddleware()

const store = configureStore({
    reducer: {
        layout,
        questionnaire
    },
    devTools: process.env.REACT_APP_ENV !== 'production',
    middleware: [sagaMiddleware]
})

sagaMiddleware.run(rootSaga)

export default store
