import { JsonUtils } from '../../utils/json.util'
import { EmploymentRelationship } from '../../utils/employment.relationship'

class WorkDayRecord {
    private _employment_relationship_type: EmploymentRelationship | undefined
    private _driver_experience_time_in_years: number | undefined
    private _sest_senat_service_usage_time_in_months: number | undefined
    private _workplace: string | undefined
    private _daily_working_hours: number | undefined
    private _weekly_working_hours: number | undefined
    private _continuous_driving_max_time_in_hours: number | undefined
    private _interday_break_in_hours: number | undefined
    private _intraday_break_in_minutes: number | undefined

    get employment_relationship_type(): EmploymentRelationship | undefined {
        return this._employment_relationship_type
    }

    set employment_relationship_type(value: EmploymentRelationship | undefined) {
        this._employment_relationship_type = value
    }

    get driver_experience_time_in_years(): number | undefined {
        return this._driver_experience_time_in_years
    }

    set driver_experience_time_in_years(value: number | undefined) {
        this._driver_experience_time_in_years = value
    }

    get sest_senat_service_usage_time_in_months(): number | undefined {
        return this._sest_senat_service_usage_time_in_months
    }

    set sest_senat_service_usage_time_in_months(value: number | undefined) {
        this._sest_senat_service_usage_time_in_months = value
    }

    get workplace(): string | undefined {
        return this._workplace
    }

    set workplace(value: string | undefined) {
        this._workplace = value
    }

    get daily_working_hours(): number | undefined {
        return this._daily_working_hours
    }

    set daily_working_hours(value: number | undefined) {
        this._daily_working_hours = value
    }

    get weekly_working_hours(): number | undefined {
        return this._weekly_working_hours
    }

    set weekly_working_hours(value: number | undefined) {
        this._weekly_working_hours = value
    }

    get continuous_driving_max_time_in_hours(): number | undefined {
        return this._continuous_driving_max_time_in_hours
    }

    set continuous_driving_max_time_in_hours(value: number | undefined) {
        this._continuous_driving_max_time_in_hours = value
    }

    get interday_break_in_hours(): number | undefined {
        return this._interday_break_in_hours
    }

    set interday_break_in_hours(value: number | undefined) {
        this._interday_break_in_hours = value
    }

    get intraday_break_in_minutes(): number | undefined {
        return this._intraday_break_in_minutes
    }

    set intraday_break_in_minutes(value: number | undefined) {
        this._intraday_break_in_minutes = value
    }

    public fromJSON(json: any): WorkDayRecord {

        if (!json) {
            return this
        }

        if (typeof json === 'string') {
            if (!JsonUtils.isJsonString(json)) {
                return this
            }
            json = JSON.parse(json)
        }

        if (json.employment_relationship_type !== undefined) {
            this.employment_relationship_type = EmploymentRelationship[json.employment_relationship_type]
        }

        if (json.driver_experience_time_in_years !== undefined) {
            this.driver_experience_time_in_years = Number(json.driver_experience_time_in_years)
        }

        if (json.sest_senat_service_usage_time_in_months !== undefined) {
            this.sest_senat_service_usage_time_in_months = Number(json.sest_senat_service_usage_time_in_months)
        }

        if (json.workplace !== undefined) {
            this.workplace = json.workplace
        }

        if (json.daily_working_hours !== undefined) {
            this.daily_working_hours = Number(json.daily_working_hours)
        }

        if (json.weekly_working_hours !== undefined) {
            this.weekly_working_hours = Number(json.weekly_working_hours)
        }

        if (json.continuous_driving_max_time_in_hours !== undefined) {
            this.continuous_driving_max_time_in_hours = Number(json.continuous_driving_max_time_in_hours)
        }

        if (json.interday_break_in_hours !== undefined) {
            this.interday_break_in_hours = Number(json.interday_break_in_hours)
        }

        if (json.intraday_break_in_minutes !== undefined) {
            this.intraday_break_in_minutes = Number(json.intraday_break_in_minutes)
        }

        return this
    }

    public toJSON(): any {
        return {
            employment_relationship_type: this.employment_relationship_type ?
                this.employment_relationship_type : undefined,
            driver_experience_time_in_years: this.driver_experience_time_in_years !== undefined ?
                this.driver_experience_time_in_years : undefined,
            sest_senat_service_usage_time_in_months: this.sest_senat_service_usage_time_in_months ?
                this.sest_senat_service_usage_time_in_months : undefined,
            workplace: this.workplace ? this.workplace : undefined,
            daily_working_hours: this.daily_working_hours ? this.daily_working_hours : undefined,
            weekly_working_hours: this.weekly_working_hours ? this.weekly_working_hours : undefined,
            continuous_driving_max_time_in_hours: this.continuous_driving_max_time_in_hours ?
                this.continuous_driving_max_time_in_hours : undefined,
            interday_break_in_hours: this.interday_break_in_hours ? this.interday_break_in_hours : undefined,
            intraday_break_in_minutes: this.intraday_break_in_minutes ? this.intraday_break_in_minutes : undefined
        }
    }

    public isEqual(compare: WorkDayRecord): boolean {
        return this.driver_experience_time_in_years === compare.driver_experience_time_in_years &&
            this.sest_senat_service_usage_time_in_months === compare.sest_senat_service_usage_time_in_months &&
            this.workplace === compare.workplace &&
            this.daily_working_hours === compare.daily_working_hours &&
            this.weekly_working_hours === compare.weekly_working_hours &&
            this.continuous_driving_max_time_in_hours === compare.continuous_driving_max_time_in_hours &&
            this.interday_break_in_hours === compare.interday_break_in_hours &&
            this.intraday_break_in_minutes === compare.intraday_break_in_minutes &&
            this.employment_relationship_type === compare.employment_relationship_type
    }
}

export default WorkDayRecord
