import { JsonUtils } from '../../utils/json.util'
import { YesOrNo } from '../../utils/yes.or.no'
import Vaccination from './vaccination'
import { Frequency } from '../../utils/frequency'

class HealthRecord {
    private _has_smoking_habit: YesOrNo | undefined
    private _smoking_habit_time_in_months: number | undefined
    private _has_social_alcoholism: YesOrNo | undefined
    private _social_alcoholism_monthly_frequency_in_days: Frequency | undefined
    private _uses_stimulants: YesOrNo | undefined
    private _stimulants: string | undefined
    private _has_chronic_illnesses: YesOrNo | undefined
    private _chronic_illnesses: string | undefined
    private _family_history: string | undefined
    private _uses_continuous_medications: YesOrNo | undefined
    private _continuous_medications: string | undefined
    private _health_complaints: string | undefined
    private _has_health_plan: YesOrNo | undefined
    private _practices_physical_activity: YesOrNo | undefined
    private _physical_activity_weekly_frequency: Frequency | undefined
    private _physical_activity_time_in_minutes: number | undefined
    private _physical_activity_type: string | undefined
    private _vaccination: Vaccination | undefined

    constructor() {
        this.vaccination = new Vaccination()
    }

    get has_smoking_habit(): YesOrNo | undefined {
        return this._has_smoking_habit
    }

    set has_smoking_habit(value: YesOrNo | undefined) {
        this._has_smoking_habit = value
    }

    get smoking_habit_time_in_months(): number | undefined {
        return this._smoking_habit_time_in_months
    }

    set smoking_habit_time_in_months(value: number | undefined) {
        this._smoking_habit_time_in_months = value
    }

    get has_social_alcoholism(): YesOrNo | undefined {
        return this._has_social_alcoholism
    }

    set has_social_alcoholism(value: YesOrNo | undefined) {
        this._has_social_alcoholism = value
    }

    get social_alcoholism_monthly_frequency_in_days(): Frequency | undefined {
        return this._social_alcoholism_monthly_frequency_in_days
    }

    set social_alcoholism_monthly_frequency_in_days(value: Frequency | undefined) {
        this._social_alcoholism_monthly_frequency_in_days = value
    }

    get uses_stimulants(): YesOrNo | undefined {
        return this._uses_stimulants
    }

    set uses_stimulants(value: YesOrNo | undefined) {
        this._uses_stimulants = value
    }

    get stimulants(): string | undefined {
        return this._stimulants
    }

    set stimulants(value: string | undefined) {
        this._stimulants = value
    }

    get has_chronic_illnesses(): YesOrNo | undefined {
        return this._has_chronic_illnesses
    }

    set has_chronic_illnesses(value: YesOrNo | undefined) {
        this._has_chronic_illnesses = value
    }

    get chronic_illnesses(): string | undefined {
        return this._chronic_illnesses
    }

    set chronic_illnesses(value: string | undefined) {
        this._chronic_illnesses = value
    }

    get family_history(): string | undefined {
        return this._family_history
    }

    set family_history(value: string | undefined) {
        this._family_history = value
    }

    get uses_continuous_medications(): YesOrNo | undefined {
        return this._uses_continuous_medications
    }

    set uses_continuous_medications(value: YesOrNo | undefined) {
        this._uses_continuous_medications = value
    }

    get continuous_medications(): string | undefined {
        return this._continuous_medications
    }

    set continuous_medications(value: string | undefined) {
        this._continuous_medications = value
    }

    get health_complaints(): string | undefined {
        return this._health_complaints
    }

    set health_complaints(value: string | undefined) {
        this._health_complaints = value
    }

    get has_health_plan(): YesOrNo | undefined {
        return this._has_health_plan
    }

    set has_health_plan(value: YesOrNo | undefined) {
        this._has_health_plan = value
    }

    get practices_physical_activity(): YesOrNo | undefined {
        return this._practices_physical_activity
    }

    set practices_physical_activity(value: YesOrNo | undefined) {
        this._practices_physical_activity = value
    }

    get physical_activity_weekly_frequency(): Frequency | undefined {
        return this._physical_activity_weekly_frequency
    }

    set physical_activity_weekly_frequency(value: Frequency | undefined) {
        this._physical_activity_weekly_frequency = value
    }

    get physical_activity_time_in_minutes(): number | undefined {
        return this._physical_activity_time_in_minutes
    }

    set physical_activity_time_in_minutes(value: number | undefined) {
        this._physical_activity_time_in_minutes = value
    }

    get physical_activity_type(): string | undefined {
        return this._physical_activity_type
    }

    set physical_activity_type(value: string | undefined) {
        this._physical_activity_type = value
    }

    get vaccination(): Vaccination | undefined {
        return this._vaccination
    }

    set vaccination(value: Vaccination | undefined) {
        this._vaccination = value
    }

    public fromJSON(json: any): HealthRecord {

        if (!json) {
            return this
        }

        if (typeof json === 'string') {
            if (!JsonUtils.isJsonString(json)) {
                return this
            }
            json = JSON.parse(json)
        }

        if (json.has_smoking_habit !== undefined) {
            this.has_smoking_habit = YesOrNo[json.has_smoking_habit]
        }

        if (json.smoking_habit_time_in_months !== undefined) {
            this.smoking_habit_time_in_months = Number(json.smoking_habit_time_in_months)
        }

        if (json.has_social_alcoholism !== undefined) {
            this.has_social_alcoholism = YesOrNo[json.has_social_alcoholism]
        }

        if (json.social_alcoholism_monthly_frequency_in_days !== undefined) {
            this.social_alcoholism_monthly_frequency_in_days = Frequency[json.social_alcoholism_monthly_frequency_in_days]
        }

        if (json.uses_stimulants !== undefined) {
            this.uses_stimulants = YesOrNo[json.uses_stimulants]
        }

        if (json.stimulants !== undefined) {
            this.stimulants = json.stimulants
        }

        if (json.has_chronic_illnesses !== undefined) {
            this.has_chronic_illnesses = YesOrNo[json.has_chronic_illnesses]
        }

        if (json.chronic_illnesses !== undefined) {
            this.chronic_illnesses = json.chronic_illnesses
        }

        if (json.family_history !== undefined) {
            this.family_history = json.family_history
        }

        if (json.uses_continuous_medications !== undefined) {
            this.uses_continuous_medications = YesOrNo[json.uses_continuous_medications]
        }

        if (json.continuous_medications !== undefined) {
            this.continuous_medications = json.continuous_medications
        }

        if (json.health_complaints !== undefined) {
            this.health_complaints = json.health_complaints
        }

        if (json.has_health_plan !== undefined) {
            this.has_health_plan = YesOrNo[json.has_health_plan]
        }

        if (json.practices_physical_activity !== undefined) {
            this.practices_physical_activity = YesOrNo[json.practices_physical_activity]
        }

        if (json.physical_activity_weekly_frequency !== undefined) {
            this.physical_activity_weekly_frequency = Frequency[
                json.physical_activity_weekly_frequency
                ]
        }

        if (json.physical_activity_time_in_minutes !== undefined) {
            this.physical_activity_time_in_minutes = Number(json.physical_activity_time_in_minutes)
        }

        if (json.physical_activity_type !== undefined) {
            this.physical_activity_type = json.physical_activity_type
        }

        if (json.vaccination !== undefined) {
            this.vaccination = new Vaccination().fromJSON(json.vaccination)
        }

        return this
    }

    public toJSON(): any {
        return {
            has_smoking_habit: this.has_smoking_habit ? this.has_smoking_habit : undefined,
            smoking_habit_time_in_months: this.smoking_habit_time_in_months ?
                this.smoking_habit_time_in_months : undefined,
            has_social_alcoholism: this.has_social_alcoholism ? this.has_social_alcoholism : undefined,
            social_alcoholism_monthly_frequency_in_days: this.social_alcoholism_monthly_frequency_in_days ?
                this.social_alcoholism_monthly_frequency_in_days
                : undefined,
            uses_stimulants: this.uses_stimulants ? this.uses_stimulants : undefined,
            stimulants: this.stimulants ? this.stimulants : undefined,
            has_chronic_illnesses: this.has_chronic_illnesses ? this.has_chronic_illnesses : undefined,
            chronic_illnesses: this.chronic_illnesses ? this.chronic_illnesses : undefined,
            family_history: this.family_history ? this.family_history : undefined,
            uses_continuous_medications: this.uses_continuous_medications ? this.uses_continuous_medications : undefined,
            continuous_medications: this.continuous_medications ?
                this.continuous_medications : undefined,
            health_complaints: this.health_complaints ?
                this.health_complaints : undefined,
            has_health_plan: this.has_health_plan ? this.has_health_plan : undefined,
            practices_physical_activity: this.practices_physical_activity ?
                this.practices_physical_activity : undefined,
            physical_activity_weekly_frequency: this.physical_activity_weekly_frequency ?
                this.physical_activity_weekly_frequency : undefined,
            physical_activity_time_in_minutes: this.physical_activity_time_in_minutes ?
                this.physical_activity_time_in_minutes : undefined,
            physical_activity_type: this.physical_activity_type ?
                this.physical_activity_type : undefined,
            vaccination: this.vaccination?.toJSON()
        }
    }

    public isEqual(compare: HealthRecord): boolean {
        const vaccination: boolean = this.vaccination && compare.vaccination ?
            this.vaccination.isEqual(compare.vaccination)
            : this.vaccination === compare.vaccination

        return this.has_smoking_habit === compare.has_smoking_habit &&
            this.smoking_habit_time_in_months === compare.smoking_habit_time_in_months &&
            this.has_social_alcoholism === compare.has_social_alcoholism &&
            this.social_alcoholism_monthly_frequency_in_days === compare.social_alcoholism_monthly_frequency_in_days &&
            this.uses_stimulants === compare.uses_stimulants &&
            this.stimulants === compare.stimulants &&
            this.has_chronic_illnesses === compare.has_chronic_illnesses &&
            this.chronic_illnesses === compare.chronic_illnesses &&
            this.family_history === compare.family_history &&
            this.uses_continuous_medications === compare.uses_continuous_medications &&
            this.continuous_medications === compare.continuous_medications &&
            this.health_complaints === compare.health_complaints &&
            this.has_health_plan === compare.has_health_plan &&
            this.practices_physical_activity === compare.practices_physical_activity &&
            this.physical_activity_weekly_frequency === compare.physical_activity_weekly_frequency &&
            this.physical_activity_time_in_minutes === compare.physical_activity_time_in_minutes &&
            this.physical_activity_type === compare.physical_activity_type &&
            vaccination

    }
}

export default HealthRecord
