export enum ThemeMode {
    LIGHT = 'light',
    DARK = 'dark'
}

export const parseStringToThemeMode = (theme: string): ThemeMode => {
    switch (theme) {
        case 'light':
            return ThemeMode.LIGHT
        case 'dark':
            return ThemeMode.DARK
    }
    return ThemeMode.LIGHT
}

const DEFAULT_THEME: any = {
    spacing: 10,
    palette: {
        primary: { main: '#2e72e0ff' },
        secondary: { main: '#003770ff' },
        accent: { main: '#AAD05E' },
        background: { default: '#F0F0F0' },
        error: { main: '#A94442' },
        warning: { main: '#FF7043' },
        info: { main: '#165C73' },
        success: { main: '#50BB98' }
    },
    typography: {
        fontFamily: 'Roboto'
    }
}

const DARK_THEME: any = {
    ...DEFAULT_THEME,
    palette: {
        ...DEFAULT_THEME.palette,

        mode: 'dark',

        background: {
            default: '#212121',
            paper: '#424242'
        },

        text: {
            primary: '#FFFFFF'
        }
    }
}

const LIGHT_THEME: any = {
    ...DEFAULT_THEME,
    palette: {
        ...DEFAULT_THEME.palette,

        mode: 'light',

        text: {
            primary: '#666666'
        }
    }
}

const CONFIG_THEME = {
    light: LIGHT_THEME,
    dark: DARK_THEME
}

export default CONFIG_THEME
