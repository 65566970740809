import { Action, ThunkAction } from '@reduxjs/toolkit'
import store from './index'

export enum AsyncStateStatus {
    INITIAL = 'INITIAL',
    LOADING = 'LOADING',
    SUCCESS = 'SUCCESS',
    FAILURE = 'FAILURE',
}

/**
 * Search interface
 */
export interface IFilter {
    startDate: string
    endDate?: string
}

/**
 * Paginator interface
 */
export interface IPaginator {
    first: number
    rows: number
    page: number
    pageCount: number
    totalRecords: number
    filter: IFilter
}

/**
 * Axios response type
 */
export interface IAxiosResponse<T = any> {
    data: T
    headers: any
}

export type ApplicationState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, ApplicationState, unknown, Action<string>>
