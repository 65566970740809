import React, { Component, Suspense } from 'react'
import { connect } from 'react-redux'
import { createTheme, CssBaseline, LinearProgress, ThemeProvider as MuiThemeProvider } from '@mui/material'
import { withTranslation, WithTranslation } from 'react-i18next'
import * as Locales from '@mui/material/locale'
import { RouterProvider } from 'react-router-dom'
import CONFIG_THEME, { ThemeMode } from './material.theme'
import { LanguageOptions, parseEnumLanguageToString, parseLanguageToLocalization } from './i18n'

import router from './routes'
import ErrorBoundary from './components/layout/error.boundary'
import { ApplicationState } from './store/root.types'

interface Props {
    readonly language: LanguageOptions
    readonly themeMode: ThemeMode
}

type IJoinProps = Props & WithTranslation

/**
 * Component that organizes the system theme.
 * @category Source
 * @subcategory Render
 * @property {LanguageOptions} language System language
 * @property {ThemeMode} themeMode Material UI theme
 * @property {Function} changeLanguage Material UI theme
 * @property {Function} changeLanguage Material UI theme
 */
class ThemeProviderComponent extends Component<IJoinProps> {
    constructor(props: IJoinProps) {
        super(props)
        this.setLanguage = this.setLanguage.bind(this)
    }

    /**
     * Method belonging to the component's life cycle, triggered immediately after any update occurs.
     * <b><small><i>This method is not called by the initial render.</i></small></b>
     * @see {@link https://pt-br.reactjs.org/docs/react-component.html#componentdidupdate}
     * @param {Readonly<IJoinProps} prevProps Set of component properties before update occurs.
     * @param {Readonly<{}>} prevState Property set of the component's internal state before update occurs
     * @param {any} snapshot
     * @return {void}
     */
    public componentDidUpdate(prevProps: Readonly<IJoinProps>, prevState: Readonly<{}>, snapshot?: any): void {
        const { language } = this.props
        if (language !== prevProps.language) {
            this.setLanguage(language)
        }
    }

    public render() {
        const { themeMode, language } = this.props

        return <MuiThemeProvider
            theme={createTheme(CONFIG_THEME[themeMode], Locales[parseLanguageToLocalization(language)])}>
            <ErrorBoundary>
                <React.Fragment>
                    <CssBaseline/>
                    <Suspense fallback={<LinearProgress/>}>
                        <RouterProvider router={router}/>
                    </Suspense>
                </React.Fragment>
            </ErrorBoundary>
        </MuiThemeProvider>
    }

    private setLanguage(language: LanguageOptions): void {
        this.props.i18n.changeLanguage(parseEnumLanguageToString(language)).then()
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    language: state.layout.language,
    themeMode: state.layout.themeMode
})

const ThemeWithTranslation: any = withTranslation()(ThemeProviderComponent)

const ThemeProvider: any = connect(mapStateToProps, undefined)(ThemeWithTranslation)

export default ThemeProvider
