import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'
import { AsyncStateStatus } from '../root.types'
import {
    IActionChangeDialogCompleted,
    IActionChangeDialogView,
    IActionChangeSelected,
    IActionCreate,
    IActionCreateFailure,
    IActionLoad,
    IActionLoadSuccess,
    IActionGeneratePdf,
    QuestionnaireState
} from './types'
import Questionnaire from '../../application/domain/models/questionnaire/questionnaire'
import { MessageType } from '../../application/domain/utils/message.type'

const initialState: QuestionnaireState = {
    create: {
        data: new Questionnaire(),
        status: AsyncStateStatus.INITIAL,
        dialog: false,
        error: {}
    },
    list: {
        data: [],
        status: AsyncStateStatus.INITIAL,
        paginator: {
            first: 0,
            rows: 10,
            page: 0,
            pageCount: 0,
            totalRecords: 0,
            filter: {
                startDate: '',
                endDate: ''
            }
        }
    },
    selected: {
        ids: []
    },
    prevData: new Questionnaire(),
    completed: false
}

export const questionnaireSlice = createSlice({
    name: '@questionnaire',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        listReset: (state: Draft<QuestionnaireState>) => {
            state.list = initialState.list
        },
        createReset: (state: Draft<QuestionnaireState>) => {
            state.create = initialState.create
            state.prevData = initialState.prevData
        },
        changeDialogView: (state: Draft<QuestionnaireState>, action: PayloadAction<IActionChangeDialogView>) => {
            const { dialog, data } = action.payload
            const message = { type: MessageType.QUESTIONNAIRE_CHANGE_DIALOG, payload: { dialog } }
            window.parent.postMessage(message, `${process.env.REACT_APP_MAIN_APP}`)
            state.create.dialog = dialog
            state.create.data = data
        },
        createRequest: (state: Draft<QuestionnaireState>, action: PayloadAction<IActionCreate>) => {
            const { data } = action.payload
            state.prevData = state.create.data
            state.create.data = data
            state.create.status = AsyncStateStatus.LOADING
        },
        createSuccess: (state: Draft<QuestionnaireState>, action: PayloadAction<IActionCreate>) => {
            const { data } = action.payload
            state.create.data = data
            state.create.status = AsyncStateStatus.SUCCESS
            state.prevData = data
            state.list.data = [...state.list.data, data]
        },
        createFailure: (state: Draft<QuestionnaireState>, action: PayloadAction<IActionCreateFailure>) => {
            const { data } = action.payload
            state.create.error = data
            state.create.status = AsyncStateStatus.FAILURE
        },
        findRequest: (state: Draft<QuestionnaireState>) => {
            state.create.status = AsyncStateStatus.LOADING
        },
        findSuccess: (state: Draft<QuestionnaireState>, action: PayloadAction<IActionCreate>) => {
            const { data } = action.payload
            state.create.data = data
            state.create.status = AsyncStateStatus.SUCCESS
            state.prevData = data
        },
        findFailure: (state: Draft<QuestionnaireState>) => {
            state.create.status = AsyncStateStatus.FAILURE
        },
        loadRequest: (state: Draft<QuestionnaireState>, action: PayloadAction<IActionLoad>) => {
            const { paginator } = action.payload
            if (paginator) {
                state.list.paginator = paginator
            }
            state.list.status = AsyncStateStatus.LOADING
            state.list.data = []
        },
        loadSuccess: (state: Draft<QuestionnaireState>, action: PayloadAction<IActionLoadSuccess>) => {
            const { data, headers } = action.payload
            const paginator = {
                ...state.list.paginator,
                totalRecords: parseInt(headers['x-total-count'], 10)
            }
            state.list.data = data
            state.list.status = AsyncStateStatus.SUCCESS
            state.list.paginator = paginator
        },
        loadFailure: (state: Draft<QuestionnaireState>) => {
            state.list.status = AsyncStateStatus.FAILURE
        },
        loadMoreRequest: (state: Draft<QuestionnaireState>, action: PayloadAction<IActionLoad>) => {
            const { paginator } = action.payload
            if (paginator) {
                state.list.paginator = paginator
            }
            state.list.status = AsyncStateStatus.LOADING
        },
        loadMoreSuccess: (state: Draft<QuestionnaireState>, action: PayloadAction<IActionLoadSuccess>) => {
            const { data, headers } = action.payload
            const paginator = {
                ...state.list.paginator,
                totalRecords: parseInt(headers['x-total-count'], 10)
            }
            state.list.data = state.list.data.concat(data)
            state.list.status = AsyncStateStatus.SUCCESS
            state.list.paginator = paginator
        },
        loadMoreFailure: (state: Draft<QuestionnaireState>) => {
            state.list.status = AsyncStateStatus.FAILURE
        },
        updateRequest: (state: Draft<QuestionnaireState>) => {
            state.prevData = state.create.data
            state.create.status = AsyncStateStatus.LOADING
        },
        updateSuccess: (state: Draft<QuestionnaireState>, action: PayloadAction<IActionCreate>) => {
            const { data } = action.payload
            state.create.data = data
            state.create.status = AsyncStateStatus.SUCCESS
            state.prevData = data
            state.list.data = state.list.data?.map((questItem: Draft<Questionnaire>) => {
                if (questItem.id === data.id) {
                    return data
                }
                return questItem
            }) || []
        },
        updateFailure: (state: Draft<QuestionnaireState>) => {
            state.create.status = AsyncStateStatus.FAILURE
        },
        changeDialogCompleted: (state: Draft<QuestionnaireState>, action: PayloadAction<IActionChangeDialogCompleted>) => {
            const { completed } = action.payload
            const message = { type: MessageType.QUESTIONNAIRE_CHANGE_DIALOG, payload: { dialog: completed } }
            window.parent.postMessage(message, `${process.env.REACT_APP_MAIN_APP}`)
            state.completed = completed
        },
        sendQuestionnaire: (state: Draft<QuestionnaireState>, action: PayloadAction<IActionGeneratePdf>) => {
            const { data } = action.payload
            const message = {
                type: MessageType.GENERATE_PDF,
                payload: data?.map((item: Questionnaire) => item?.toJSON()) || []
            }
            window.parent.postMessage(message, `${process.env.REACT_APP_MAIN_APP}`)
            state.list.status = AsyncStateStatus.SUCCESS
        },
        changeSelected: (state: Draft<QuestionnaireState>, action: PayloadAction<IActionChangeSelected>) => {
            const { ids } = action.payload
            state.selected.ids = ids
        }
    }
})

/**
 * <h5>Functions that trigger actions related to the management of questionnaires in the system.</h5>
 * @see {@link https://redux-toolkit.js.org/usage/usage-guide#simplifying-slices-with-createslice}
 * @typedef QuestionnaireActions
 * @namespace QuestionnaireActions
 * @category React
 * @subcategory Redux / Actions
 * @property {Function} listReset Reduction function to reset the listing state
 * @property {Function} createReset Reduction function to reset the creation state.
 * @property {Function} changeDialogRemove Reducer function to change the visibility of the remove dialog.
 * @property {Function} changeDialogView Reducer function to change the visibility of the preview dialog.
 * @property {Function} createRequest Reduction function to trigger the sending of data from a new questionnaire.
 * @property {Function} createSuccess Reduction function to process data from a successfully saved questionnaire.
 * @property {Function} createFailure Reduction function to handle the scenario of failure to create a new questionnaire.
 * @property {Function} findRequest Reducer function to trigger the loading of questionnaire data.
 * @property {Function} findSuccess Reduction function to handle the scenario of success in obtaining data from a
 * questionnaire.
 * @property {Function} findFailure Reduction function to handle the scenario of failure to obtain data from a
 * questionnaire.
 * @property {Function} loadRequest Reducer function to trigger the loading of a questionnaire list.
 * @property {Function} loadSuccess Reduction function to handle the successful scenario of loading a questionnaire list.
 * @property {Function} loadFailure Reduction function to handle the scenario of failure to load a questionnaire list.
 * @property {Function} loadMoreRequest Reducer function to trigger the loading with concatenation of a list of
 * questionnaires.
 * @property {Function} loadMoreSuccess Reducer function to handle the successful scenario of loading with concatenation
 * a list of questionnaires.
 * @property {Function} loadMoreFailure Reducer function to deal with the scenario of loading failure with concatenation
 * of a list of questionnaires.
 * @property {Function} updateRequest Reducer function to trigger the update of a questionnaire's data.
 * @property {Function} updateSuccess Reduction function to handle the scenario of success in updating questionnaire data.
 * @property {Function} updateFailure Reduction function to handle the scenario of failure to update questionnaire data.
 * @property {Function} changeDialogCompleted Reducer function to change the visibility of the dialog that informs the
 * complete completion of the questionnaire.
 * @property {Function} changeSelected Reducer function to change the questionnaires selecteds.
 */
export const QuestionnaireActions = questionnaireSlice.actions

export default questionnaireSlice.reducer
