import { LanguageOptions } from '../../../../i18n'
import Moment from 'moment'
import { JsonUtils } from '../../utils/json.util'
import { TypeUser } from '../../utils/type.user'
import { Gender } from '../../utils/gender'


export default class User {
    // Required
    private _name: string | undefined
    private _birth_date: Date | undefined
    private _gender: Gender | undefined
    // optional
    private _phone_number: string | undefined
    private _language: LanguageOptions | undefined
    // Readonly
    private _id: string | undefined
    private _last_login: string | undefined
    private _created_at: string | undefined
    private _updated_at: string | undefined
    private _type: TypeUser | undefined

    get name(): string | undefined {
        return this._name
    }

    set name(value: string | undefined) {
        this._name = value
    }

    get birth_date(): Date | undefined {
        return this._birth_date
    }

    set birth_date(value: Date | undefined) {
        this._birth_date = value
    }

    get gender(): Gender | undefined {
        return this._gender
    }

    set gender(value: Gender | undefined) {
        this._gender = value
    }

    get phone_number(): string | undefined {
        return this._phone_number
    }

    set phone_number(value: string | undefined) {
        this._phone_number = value
    }

    get language(): LanguageOptions | undefined {
        return this._language
    }

    set language(value: LanguageOptions | undefined) {
        this._language = value
    }

    get id(): string | undefined {
        return this._id
    }

    set id(value: string | undefined) {
        this._id = value
    }

    get last_login(): string | undefined {
        return this._last_login
    }

    set last_login(value: string | undefined) {
        this._last_login = value
    }

    get created_at(): string | undefined {
        return this._created_at
    }

    set created_at(value: string | undefined) {
        this._created_at = value
    }

    get updated_at(): string | undefined {
        return this._updated_at
    }

    set updated_at(value: string | undefined) {
        this._updated_at = value
    }

    get type(): TypeUser | undefined {
        return this._type
    }

    set type(value: TypeUser | undefined) {
        this._type = value
    }

    public fromJSON(json: any): User {
        if (!json) {
            return this
        }

        if (typeof json === 'string') {
            if (!JsonUtils.isJsonString(json)) {
                return this
            }
            json = JSON.parse(json)
        }

        if (json.name !== undefined) {
            this.name = json.name
        }

        if (json.birth_date !== undefined) {
            this.birth_date = Moment(json.birth_date).toDate()
        }

        if (json.gender !== undefined) {
            this.gender = json.gender
        }

        if (json.phone_number !== undefined) {
            this.phone_number = json.phone_number
        }

        if (json.language !== undefined) {
            this.language = json.language
        }

        if (json.id !== undefined) {
            this.id = json.id
        }

        if (json.last_login !== undefined) {
            this.last_login = json.last_login
        }

        if (json.created_at !== undefined) {
            this.created_at = json.created_at
        }

        if (json.updated_at !== undefined) {
            this.updated_at = json.updated_at
        }

        if (json.type !== undefined) {
            this.type = json.type
        }

        return this
    }

    public toJSON(): any {
        return {
            id: this.id ? this.id : undefined,
            last_login: this.last_login ? this.last_login : undefined,
            created_at: this.created_at ? this.created_at : undefined,
            updated_at: this.updated_at ? this.updated_at : undefined,
            name: this.name ? this.name : undefined,
            birth_date: this.birth_date ? this.birth_date : null,
            gender: this.gender ? this.gender : undefined,
            phone_number: this.phone_number,
            language: this.language ? this.language : undefined,
            type: this.type ? this.type : undefined
        }
    }

}
