import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'
import { LanguageOptions } from '../../i18n'
import { ThemeMode } from '../../material.theme'
import Patient from '../../application/domain/models/patient/patient'

export interface LayoutState {
    readonly language: LanguageOptions
    readonly themeMode: ThemeMode
    readonly patient: Patient
}

export interface IActionsChangeLanguage {
    readonly language: LanguageOptions
}

export interface IActionsChangeThemeMode {
    readonly themeMode: ThemeMode
}

export interface IActionsChangePatient {
    readonly patient: Patient
}

const initialState: LayoutState = {
    language: LanguageOptions.PT_BR,
    themeMode: ThemeMode.LIGHT,
    patient: new Patient()
}

export const layoutSlice = createSlice({
    name: '@layout',
    initialState,
    reducers: {
        changeLanguage: (state: Draft<LayoutState>, action: PayloadAction<IActionsChangeLanguage>) => {
            const { language } = action.payload
            state.language = language
        },
        changeTheme: (state: Draft<LayoutState>, action: PayloadAction<IActionsChangeThemeMode>) => {
            const { themeMode } = action.payload
            state.themeMode = themeMode
        },
        changePatientSelected: (state: Draft<LayoutState>, action: PayloadAction<IActionsChangePatient>) => {
            const { patient } = action.payload
            state.patient = patient
        }
    }
})

/**
 * <h5>Actions used to manipulate the application's layout.</h5>
 * @see {@link https://redux.js.org/tutorials/fundamentals/part-3-state-actions-reducers#designing-actions}
 * @typedef LayoutActions
 * @namespace LayoutActions
 * @category React
 * @subcategory Redux / Actions
 *
 * @property {Function} changeLanguage Reduction function that changes the selected language.
 * @property {Function} changeTheme Reduction function that changes the selected theme.
 * @property {Function} changePatientSelected Reduction function that changes the selected patient.
 */
export const LayoutActions = layoutSlice.actions

export default layoutSlice.reducer
