import React, { lazy } from 'react'
import { createBrowserRouter } from 'react-router-dom'
import ProtectRouter from '../components/protect.router'
import Redirect from '../components/redirect'
import EntryPoint from '../containers/entrypoint'
import NotFound from '../components/layout/escape.pages/not.found'
import AccessDenied from '../components/layout/escape.pages/access.denied'

const Compose = lazy(() => import('../containers/compose'))
const ListOfQuestionnaires = lazy(() => import('../containers/questionnaires/list'))
const CreateUpdateQuestionnaire = lazy(() => import('../containers/questionnaires/create.update'))

const router: any = createBrowserRouter([
    {
        path: '/entrypoint',
        element: <EntryPoint />
    },
    {
        path: '/app/patients/:patientId/questionnaires',
        element: <ProtectRouter privateRoute={true}>
            <Compose />
        </ProtectRouter>,
        children: [
            {
                path: '/app/patients/:patientId/questionnaires',
                element: <ProtectRouter privateRoute={true} scopes={['qt:ra', 'qt:r']}>
                    <ListOfQuestionnaires />
                </ProtectRouter>
            },
            {
                path: '/app/patients/:patientId/questionnaires/new',
                element: <ProtectRouter privateRoute={true} scopes={['qt:c']}>
                    <CreateUpdateQuestionnaire />
                </ProtectRouter>
            },
            {
                path: '/app/patients/:patientId/questionnaires/:questionnaireId',
                element: <ProtectRouter privateRoute={true} scopes={['qt:u']}>
                    <CreateUpdateQuestionnaire />
                </ProtectRouter>
            }
        ]
    },
    {
        path: '/access_denied',
        element: <AccessDenied />
    },
    {
        path: '/not_found',
        element: <NotFound />
    },
    {
        path: '*',
        element: <Redirect to="not_found" />
    }
])

export default router
