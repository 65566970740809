import { JsonUtils } from '../../utils/json.util'
import { YesOrNo } from '../../utils/yes.or.no'

class Vaccination {
    private _mmr: YesOrNo | undefined
    private _yellow_fever: YesOrNo | undefined
    private _covid_19: YesOrNo | undefined
    private _fifteria_and_tetanus: YesOrNo | undefined
    private _hepatitis_b: YesOrNo | undefined
    private _influenza: YesOrNo | undefined

    get mmr(): YesOrNo | undefined {
        return this._mmr
    }

    set mmr(value: YesOrNo | undefined) {
        this._mmr = value
    }

    get yellow_fever(): YesOrNo | undefined {
        return this._yellow_fever
    }

    set yellow_fever(value: YesOrNo | undefined) {
        this._yellow_fever = value
    }

    get covid_19(): YesOrNo | undefined {
        return this._covid_19
    }

    set covid_19(value: YesOrNo | undefined) {
        this._covid_19 = value
    }

    get fifteria_and_tetanus(): YesOrNo | undefined {
        return this._fifteria_and_tetanus
    }

    set fifteria_and_tetanus(value: YesOrNo | undefined) {
        this._fifteria_and_tetanus = value
    }

    get hepatitis_b(): YesOrNo | undefined {
        return this._hepatitis_b
    }

    set hepatitis_b(value: YesOrNo | undefined) {
        this._hepatitis_b = value
    }

    get influenza(): YesOrNo | undefined {
        return this._influenza
    }

    set influenza(value: YesOrNo | undefined) {
        this._influenza = value
    }

    public fromJSON(json: any): Vaccination {

        if (!json) {
            return this
        }

        if (typeof json === 'string') {
            if (!JsonUtils.isJsonString(json)) {
                return this
            }
            json = JSON.parse(json)
        }

        if (json.mmr !== undefined) {
            this.mmr = YesOrNo[json.mmr]
        }

        if (json.yellow_fever !== undefined) {
            this.yellow_fever = YesOrNo[json.yellow_fever]
        }

        if (json.covid_19 !== undefined) {
            this.covid_19 = YesOrNo[json.covid_19]
        }

        if (json.fifteria_and_tetanus !== undefined) {
            this.fifteria_and_tetanus = YesOrNo[json.fifteria_and_tetanus]
        }

        if (json.hepatitis_b !== undefined) {
            this.hepatitis_b = YesOrNo[json.hepatitis_b]
        }

        if (json.influenza !== undefined) {
            this.influenza = YesOrNo[json.influenza]
        }

        return this
    }

    public toJSON(): any {
        return {
            mmr: this.mmr,
            yellow_fever: this.yellow_fever,
            covid_19: this.covid_19,
            fifteria_and_tetanus: this.fifteria_and_tetanus,
            hepatitis_b: this.hepatitis_b,
            influenza: this.influenza
        }
    }

    public isEqual(compare: Vaccination): boolean {
        return this.mmr === compare.mmr &&
            this.yellow_fever === compare.yellow_fever &&
            this.covid_19 === compare.covid_19 &&
            this.fifteria_and_tetanus === compare.fifteria_and_tetanus &&
            this.hepatitis_b === compare.hepatitis_b &&
            this.influenza === compare.influenza
    }
}

export default Vaccination
