import { Gender } from '../../utils/gender'
import { SelfRace } from '../../utils/self.race'
import { MaritalStatus } from '../../utils/marital.status'
import Address from './address'
import { EducationLevel } from '../../utils/education.level'
import { JsonUtils } from '../../utils/json.util'

class SociodemographicRecord {
    private _age: number | undefined
    private _gender: Gender | undefined
    private _self_declared_race: SelfRace | undefined
    private _marital_status: MaritalStatus | undefined
    private _address: Address | undefined
    private _salary: number | undefined
    private _children_number: number | undefined
    private _education_level: EducationLevel | undefined

    constructor() {
        this.address = new Address()
    }


    get age(): number | undefined {
        return this._age
    }

    set age(value: number | undefined) {
        this._age = value
    }

    get gender(): Gender | undefined {
        return this._gender
    }

    set gender(value: Gender | undefined) {
        this._gender = value
    }

    get self_declared_race(): SelfRace | undefined {
        return this._self_declared_race
    }

    set self_declared_race(value: SelfRace | undefined) {
        this._self_declared_race = value
    }

    get marital_status(): MaritalStatus | undefined {
        return this._marital_status
    }

    set marital_status(value: MaritalStatus | undefined) {
        this._marital_status = value
    }

    get address(): Address | undefined {
        return this._address
    }

    set address(value: Address | undefined) {
        this._address = value
    }

    get salary(): number | undefined {
        return this._salary
    }

    set salary(value: number | undefined) {
        this._salary = value
    }

    get children_number(): number | undefined {
        return this._children_number
    }

    set children_number(value: number | undefined) {
        this._children_number = value
    }

    get education_level(): EducationLevel | undefined {
        return this._education_level
    }

    set education_level(value: EducationLevel | undefined) {
        this._education_level = value
    }

    public fromJSON(json: any): SociodemographicRecord {

        if (!json) {
            return this
        }

        if (typeof json === 'string') {
            if (!JsonUtils.isJsonString(json)) {
                return this
            }
            json = JSON.parse(json)
        }

        if (json.age !== undefined) {
            this.age = Number(json.age)
        }

        if (json.gender !== undefined) {
            this.gender = Gender[json.gender]
        }

        if (json.self_declared_race !== undefined) {
            this.self_declared_race = SelfRace[json.self_declared_race]
        }

        if (json.marital_status !== undefined) {
            this.marital_status = MaritalStatus[json.marital_status]
        }

        if (json.address !== undefined) {
            this.address = new Address().fromJSON(json.address)
        }

        if (json.salary !== undefined) {
            this.salary = Number(json.salary)
        }

        if (json.children_number !== undefined) {
            this.children_number = Number(json.children_number)
        }

        if (json.education_level !== undefined) {
            this.education_level = EducationLevel[json.education_level]
        }

        return this
    }

    public toJSON(): any {
        return {
            age: this.age ? this.age : undefined,
            gender: this.gender ? this.gender : undefined,
            self_declared_race: this.self_declared_race ? this.self_declared_race : undefined,
            marital_status: this.marital_status ? this.marital_status : undefined,
            address: this.address?.toJSON(),
            salary: this.salary !== undefined ? this.salary : undefined,
            children_number: this.children_number !== undefined ? this.children_number : undefined,
            education_level: this.education_level ? this.education_level : undefined
        }
    }

    public isEqual(compare: SociodemographicRecord): boolean {
        const address: boolean = this.address && compare.address ?
            this.address.isEqual(compare.address)
            : this.address === compare.address
        return this.age === compare.age &&
            this.gender === compare.gender &&
            this.self_declared_race === compare.self_declared_race &&
            this.marital_status === compare.marital_status &&
            address &&
            this.salary === compare.salary &&
            this.children_number === compare.children_number &&
            this.education_level === compare.education_level
    }
}

export default SociodemographicRecord
