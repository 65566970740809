import { JsonUtils } from '../../utils/json.util'

class BioImpedanceRecord {
    private _body_fat_in_percentage: number | undefined
    private _skeletal_muscle_mass_in_kg: number | undefined
    private _basal_metabolic_rate_in_kcal: number | undefined
    private _visceral_fat_level: number | undefined

    get body_fat_in_percentage(): number | undefined {
        return this._body_fat_in_percentage
    }

    set body_fat_in_percentage(value: number | undefined) {
        this._body_fat_in_percentage = value
    }

    get skeletal_muscle_mass_in_kg(): number | undefined {
        return this._skeletal_muscle_mass_in_kg
    }

    set skeletal_muscle_mass_in_kg(value: number | undefined) {
        this._skeletal_muscle_mass_in_kg = value
    }

    get basal_metabolic_rate_in_kcal(): number | undefined {
        return this._basal_metabolic_rate_in_kcal
    }

    set basal_metabolic_rate_in_kcal(value: number | undefined) {
        this._basal_metabolic_rate_in_kcal = value
    }

    get visceral_fat_level(): number | undefined {
        return this._visceral_fat_level
    }

    set visceral_fat_level(value: number | undefined) {
        this._visceral_fat_level = value
    }

    public fromJSON(json: any): BioImpedanceRecord {

        if (!json) {
            return this
        }

        if (typeof json === 'string') {
            if (!JsonUtils.isJsonString(json)) {
                return this
            }
            json = JSON.parse(json)
        }

        if (json.body_fat_in_percentage !== undefined) {
            this.body_fat_in_percentage = Number(json.body_fat_in_percentage)
        }

        if (json.skeletal_muscle_mass_in_kg !== undefined) {
            this.skeletal_muscle_mass_in_kg = Number(json.skeletal_muscle_mass_in_kg)
        }

        if (json.basal_metabolic_rate_in_kcal !== undefined) {
            this.basal_metabolic_rate_in_kcal = Number(json.basal_metabolic_rate_in_kcal)
        }

        if (json.visceral_fat_level !== undefined) {
            this.visceral_fat_level = Number(json.visceral_fat_level)
        }

        return this
    }

    public toJSON(): any {
        return {
            body_fat_in_percentage: this.body_fat_in_percentage !== undefined ?
                this.body_fat_in_percentage : undefined,
            skeletal_muscle_mass_in_kg: this.skeletal_muscle_mass_in_kg !== undefined ?
                this.skeletal_muscle_mass_in_kg : undefined,
            basal_metabolic_rate_in_kcal: this.basal_metabolic_rate_in_kcal !== undefined ?
                this.basal_metabolic_rate_in_kcal : undefined,
            visceral_fat_level: this.visceral_fat_level !== undefined ? this.visceral_fat_level : undefined
        }
    }

    public isEqual(compare: BioImpedanceRecord): boolean {
        return this.body_fat_in_percentage === compare.body_fat_in_percentage &&
            this.skeletal_muscle_mass_in_kg === compare.skeletal_muscle_mass_in_kg &&
            this.basal_metabolic_rate_in_kcal === compare.basal_metabolic_rate_in_kcal &&
            this.visceral_fat_level === compare.visceral_fat_level
    }
}

export default BioImpedanceRecord
