import React, { Component, ComponentProps } from 'react'

import authService from '../../services/auth'
import Redirect from '../redirect'
import { TypeUser } from '../../application/domain/utils/type.user'
import { VerifyScopes } from '../verify.scopes'
import { VerifyUserType } from '../verify.user.type'

export enum LogicalStrategy {
    OR = 'or',
    AND = 'and'
}

interface IProps extends ComponentProps<any> {
    privateRoute?: boolean
    scopes?: string[]
    types?: TypeUser[]
    logicalStrategy?: LogicalStrategy
}

class ProtectRouterComponent extends Component<IProps> {
    public render() {
        const { children, privateRoute, types, scopes, logicalStrategy } = this.props
        const productionMode: boolean = `${process.env.REACT_APP_ENV || ''}` === 'production'
        const sameWindow: boolean = window.parent === window
        if (productionMode && sameWindow) {
            return <Redirect to="/access_denied"/>
        }
        /* Verify user is authenticated */
        if (privateRoute && !authService.isAuthenticated()) {
            return <Redirect to="/"/>
        }
        /* Make sure the user has the necessary scopes */
        if (scopes) {
            try {
                if (!VerifyScopes.validate(scopes, logicalStrategy)) {
                    throw new Error('ACCESS DENIED')
                }
            } catch (e) {
                return <Redirect to="/access_denied"/>
            }
        }
        /* Make sure the user has the necessary type */
        if (types) {
            try {
                if (!VerifyUserType.validate(types)) {
                    throw new Error('ACCESS DENIED')
                }
            } catch (e) {
                return <Redirect to="/access_denied"/>
            }
        }
        return children
    }
}

const ProtectRouter: any = ProtectRouterComponent

export default ProtectRouter
