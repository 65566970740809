import { AsyncStateStatus, IFilter, IPaginator } from '../root.types'
import Questionnaire from '../../application/domain/models/questionnaire/questionnaire'

/**
 * <h5> </h5>
 * @typedef QuestionnaireTypes
 * @namespace QuestionnaireTypes
 * @category React
 * @subcategory Redux / Types
 */

/**
 * Enum with types of actions available for the questionnaire module.
 * </br>
 * The action identifiers are:
 * <pre>
 *     <ul>
 *         <li>@questionnaire/listReset</li>
 *         <li>@questionnaire/createReset</li>
 *
 *         <li>@questionnaire/changeDialogCreate</li>
 *         <li>@questionnaire/changeSelected</li>
 *
 *         <li>@questionnaire/changeFilterPaginator</li>
 *
 *         <li>@questionnaire/createRequest</li>
 *         <li>@questionnaire/createSuccess</li>
 *         <li>@questionnaire/createFailure</li>
 *
 *         <li>@questionnaire/findRequest</li>
 *         <li>@questionnaire/findSuccess</li>
 *         <li>@questionnaire/findFailure</li>
 *
 *         <li>@questionnaire/loadRequest</li>
 *         <li>@questionnaire/loadSuccess</li>
 *         <li>@questionnaire/loadFailure</li>
 *
 *         <li>@questionnaire/loadMoreRequest</li>
 *         <li>@questionnaire/loadMoreSuccess</li>
 *         <li>@questionnaire/loadMoreFailure</li>
 *
 *         <li>@questionnaire/updateRequest</li>
 *         <li>@questionnaire/updateSuccess</li>
 *         <li>@questionnaire/updateFailure</li>
 *
 *      </ul>
 * </pre>
 * @memberof QuestionnaireTypes
 * @function
 * @category React
 * @subcategory Redux / Types
 */
export enum QuestionnaireTypes {
    LIST_RESET = '@questionnaire/listReset',
    CREATE_RESET = '@questionnaire/createReset',

    CHANGE_DIALOG_REMOVE = '@questionnaire/changeDialogRemove',
    CHANGE_SELECTED = '@questionnaire/changeSelected',

    CREATE_REQUEST = '@questionnaire/createRequest',
    CREATE_SUCCESS = '@questionnaire/createSuccess',
    CREATE_FAILURE = '@questionnaire/createFailure',

    FIND_REQUEST = '@questionnaire/findRequest',
    FIND_SUCCESS = '@questionnaire/findSuccess',
    FIND_FAILURE = '@questionnaire/findFailure',

    LOAD_REQUEST = '@questionnaire/loadRequest',
    LOAD_SUCCESS = '@questionnaire/loadSuccess',
    LOAD_FAILURE = '@questionnaire/loadFailure',

    LOAD_MORE_REQUEST = '@questionnaire/loadMoreRequest',
    LOAD_MORE_SUCCESS = '@questionnaire/loadMoreSuccess',
    LOAD_MORE_FAILURE = '@questionnaire/loadMoreFailure',

    UPDATE_REQUEST = '@questionnaire/updateRequest',
    UPDATE_SUCCESS = '@questionnaire/updateSuccess',
    UPDATE_FAILURE = '@questionnaire/updateFailure'
}

/**
 * @memberof QuestionnaireTypes
 * @interface
 * @category React
 * @subcategory Redux / Types
 * @property {Questionnaire} data Questionnaire data.
 */
export interface IActionCreate {
    data: Questionnaire
}

/**
 * @memberof QuestionnaireTypes
 * @interface
 * @category React
 * @subcategory Redux / Types
 * @property {any} data Error received when trying to create a new questionnaire.
 */
export interface IActionCreateFailure {
    data: any
}

/**
 * @memberof QuestionnaireTypes
 * @interface
 * @category React
 * @subcategory Redux / Types
 * @property {string} patientId Patient identifier selected.
 * @property {string} questionnaireId Questionnaire identifier selected.
 */
export interface IActionFind {
    patientId: string
    questionnaireId: string
}

/**
 * @memberof QuestionnaireTypes
 * @interface
 * @category React
 * @subcategory Redux / Types
 * @property {string} patientId Patient identifier selected.
 * @property {IPaginator} [paginator] Paginator settings data.
 */
export interface IActionLoad {
    patientId: string
    paginator?: IPaginator
}

/**
 * @memberof QuestionnaireTypes
 * @interface
 * @category React
 * @subcategory Redux / Types
 * @property {Questionnaire[]} data List of questionnaires.
 * @property {any} headers Headers settings data.
 */
export interface IActionLoadSuccess {
    data: Questionnaire[]
    headers: any
}

/**
 * @memberof QuestionnaireTypes
 * @interface
 * @category React
 * @subcategory Redux / Types
 * @property {string} patientId Patient identifier selected.
 * @property {string[]} ids List of questionnaires identifiers for remove.
 */
export interface IActionRemove {
    patientId: string
    ids: string[]
}

/**
 * @memberof QuestionnaireTypes
 * @interface
 * @category React
 * @subcategory Redux / Types
 * @property {Questionnaire[]} data List of questionnaires. 
 */
export interface IActionGeneratePdf {
    data: Questionnaire[]
}
/**
 * @memberof QuestionnaireTypes
 * @interface
 * @category React
 * @subcategory Redux / Types
 * @property {Questionnaire} data Questionnaire data selected.
 * @property {boolean} dialog Dialog status.
 */
export interface IActionChangeDialogCreate {
    data: Questionnaire
    dialog: boolean
}


/**
 * @memberof QuestionnaireTypes
 * @interface
 * @category React
 * @subcategory Redux / Types
 * @property {string[]} ids List of questionnaires selected.
 * @property {boolean} dialog Dialog status.
 */
export interface IActionChangeDialogRemove {
    ids: string[]
    dialog: boolean
}

/**
 * @memberof QuestionnaireTypes
 * @interface
 * @category React
 * @subcategory Redux / Types
 * @property {string[]} ids List of questionnaires selected. 
 */
export interface IActionChangeSelected {
    ids: string[]
}

/**
 * @memberof QuestionnaireTypes
 * @interface
 * @category React
 * @subcategory Redux / Types
 * @property {string[]} ids List of questionnaires selected.
 * @property {boolean} dialog Dialog status.
 */
export interface IActionChangeDialogView {
    data: Questionnaire
    dialog: boolean
}

/**
 * @memberof QuestionnaireTypes
 * @interface
 * @category React
 * @subcategory Redux / Types
 * @property {IFilter} filter Filter settings.
 */
export interface IActionChangeFilterPaginator {
    filter: IFilter
}

/**
 * @memberof QuestionnaireTypes
 * @interface
 * @category React
 * @subcategory Redux / Types
 * @property {boolean} completed Completed dialog status.
 */
export interface IActionChangeDialogCompleted {
    completed: boolean
}

/**
 * @memberof QuestionnaireTypes
 * @interface
 * @category React
 * @subcategory Redux / Types
 * @property {Questionnaire} data
 * @property {AsyncStateStatus} status
 * @property {boolean} dialog
 */
interface ICreateState {
    data: Questionnaire
    status: AsyncStateStatus
    dialog: boolean
    error: any
}

/**
 * @memberof QuestionnaireTypes
 * @interface
 * @category React
 * @subcategory Redux / Types
 * @property {Questionnaire[]} data
 * @property {AsyncStateStatus} status
 * @property {IPaginator} paginator
 */
interface IListState {
    data: Questionnaire[]
    status: AsyncStateStatus
    paginator: IPaginator
}

/**
 * @memberof QuestionnaireTypes
 * @interface
 * @category React
 * @subcategory Redux / Types
 * @property {string[]} ids
 * @property {boolean} dialog
 * @property {AsyncStateStatus} status
 */

/**
 * @memberof QuestionnaireTypes
 * @interface
 * @category React
 * @subcategory Redux / Types
 * @property {string[]} ids
 */
interface ISelectedState {
    ids: string[]
}

/**
 * @memberof QuestionnaireTypes
 * @interface
 * @category React
 * @subcategory Redux / Types
 * @property {ICreateState} create
 * @property {IListState} list
 * @property {ISelectedState} selected
 * @property {Questionnaire} prevData
 * @property {false} completed
 */
export interface QuestionnaireState {
    create: ICreateState
    list: IListState
    selected: ISelectedState
    prevData: Questionnaire
    completed: boolean
}


