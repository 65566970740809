export enum TransportTypes {
    car = 'car',
    truck = 'truck',
    bus = 'bus',
    waterway = 'waterway',
    subway_and_similar = 'subway_and_similar',
    trains_and_similar = 'trains_and_similar',
    airplanes_and_similar = 'airplanes_and_similar',
    motorcycle = 'motorcycle',
    bicycle = 'bicycle',
    other = 'other'
}
